function getEnv() {
  // 如果有 FX 的变量，则直接获取变量。
  if (window.FX_DEPLOY_ENV) {
    return window.FX_DEPLOY_ENV;
  }

  const url = window.location.href;

  // 本地环境
  if (
    url.indexOf("localhost") !== -1 ||
    url.indexOf("127.0.0.1") !== -1 ||
    url.indexOf("192.168") !== -1 ||
    url.indexOf("10.110") !== -1 ||
    url.indexOf("10.161") !== -1 ||
    url.indexOf("10.160") !== -1 ||
    url.indexOf("10.145") !== -1
  ) {
    // Local
    return "dev";
  }

  // 开发环境
  if (url.indexOf("-dev") !== -1) {
    return "dev";
  }

  // 测试环境
  if (url.indexOf("-test") !== -1) {
    return "test";
  }

  // stg 环境
  if (url.indexOf("-stg") !== -1) {
    return "stg";
  }

  // 生产环境
  return "prod";
}

export const env = getEnv();
