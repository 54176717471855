// 主页-首屏幕
export const homeBannerData = {
  videoUrl:
    "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/官网视频-首页.mp4",
  title: "蔚来校园招聘",
  subtitle: "2025届秋季校园招聘开启",
  deliverUrl:
    "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978%2C7392930971669285147%2C7383991896182343963&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=",
};
// 手机端-主页-首屏幕
export const mobileHomeBannerData = homeBannerData;
// 主页-校园招聘项目
export const homeRecruitModules = [
  {
    id: "campus",
    title: "2025届秋季校园招聘",
    desc: "一同见证并参与推动智能电动汽车最前沿的技术和商业模式",
    descUrl: "#/learnNioMore",
    deliverUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978%2C7383991896182343963&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=",
  },
  {
    id: "intern",
    title: "蔚来日常实习生",
    desc: "在真实的研发、生产、用户服务情境中学习与实践",
    descUrl: "",
    deliverUrl: "https://nio.jobs.feishu.cn/intern",
  },
  {
    id: "region",
    title: "蔚来区域公司\n2025届校园招聘",
    desc: "蔚来用户触点第一线\n分享欢乐 共同成长",
    descUrl: "#/trends?active=false&dataId=10&demo=1",
    deliverUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352401153657142",
  },
  {
    id: "onvo",
    title: "乐道区域公司\n2025届校园招聘",
    desc: "你的未来之路是乐道\n为家庭用户提供超越期待的全程体验",
    descUrl: "#/onvo",
    deliverUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755",
  },
];
// 主页-校园招聘项目-右侧图片，手机端顶部图片
export const homeRecruitImage =
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/%E6%A0%A1%E5%9B%AD%E6%8B%9B%E8%81%98%E5%9B%BE%E7%89%87.png?imageView2/interlace/1";
const mobileHomeRecruitModulesDifference = [
  {
    id: "campus",
    cardImage: require("@/assets/img/school/campus-06.png"),
    descUrl: "#/mobile/traineePlan",
  },
  {
    id: "region",
    desc: "分享欢乐 共同成长",
    cardImage: require("@/assets/img/school/campus-02.jpg"),
    descUrl: "#/mobile/mobile",
  },
  {
    id: "onvo",
    desc: "乐在其中 大有可为",
    cardImage: require("@/assets/img/school/campus-07.jpg"),
    descUrl: "#/mobile/onvo",
  },
  {
    id: "intern",
    desc: "以热爱 致未来",
    cardImage: require("@/assets/img/school/campus-04.jpg"),
    descUrl: "",
  },
];
// 手机端-主页-校园招聘项目
export const mobileHomeRecruitModules = mobileHomeRecruitModulesDifference.map(
  (item) => ({
    ...homeRecruitModules.find((homeItem) => homeItem.id === item.id),
    ...item,
  })
);
// 主页-探索你感兴趣的岗位
export const homePostData = [
  {
    key: "szjs",
    img: require("@/assets/img/szjs.png"),
    text: "数字技术类",
    desc: "包含智能驾驶、芯片研发、算法、软件研发、智能硬件等岗位，专注前瞻性数字技术平台的规划、开发与运维。",
    linkUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978&type=&job_hot_flag=&current=1&limit=10&functionCategory=7257443347899091255%2C7257442883007203643%2C7257443883901208891%2C7257443555114305853%2C7331969227841374490&tag=",
  },
  {
    key: "znzz",
    img: require("@/assets/img/znzz.png"),
    text: "智能制造类",
    desc: "包含制造工艺、制造运营、设备管理、物流及供应链发展类岗位，实现敏捷、高质量的产品交付。",
    linkUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978&type=&job_hot_flag=&current=1&limit=10&functionCategory=7257443251035621688&tag=",
  },
  {
    key: "gcjsl",
    img: require("@/assets/img/gcjs.png"),
    text: "工程技术类",
    desc: "包含整车工程、机械与电气工程、质量管理、硬件测试等岗位，提出最优技术方案，确保交付满足进度与性能的要求。",
    linkUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978&type=&job_hot_flag=&current=1&limit=10&functionCategory=7257444122821691703%2C7257443347898927415&tag=",
  },
  {
    key: "dldcjdq",
    img: require("@/assets/img/dldc.png"),
    text: "动力电池类",
    desc: "从基础材料研究、电池系统开发和软件控制，到大数据平台，致力于自主研发全球领先的长续航、高安全、高性能电池系统。",
    linkUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978&type=&job_hot_flag=&current=1&limit=10&functionCategory=7257443988050692413%2C7257445186794490170&tag=",
  },
  {
    key: "yhyfw",
    img: require("@/assets/img/yhfw.jpg"),
    text: "用户与服务类",
    desc: "我们持续创造愉悦的生活方式，并为用户提供超越期待的全程体验。打造一个充满活力的社区，与用户分享欢乐，共同成长。",
    linkUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978&type=&job_hot_flag=&current=1&limit=10&functionCategory=7257443440471542077%2C7257445015411394873%2C7257444123313080636%2C7257445003688626488%2C7257444698611271993&tag=",
  },
  {
    key: "znzc",
    img: require("@/assets/img/znzc.png"),
    text: "职能与支持类",
    desc: "包含人力资源、采购、财务、金融、行政、战略及业务规划等岗位，为公司提供专业、高效、有温度的运营支持。",
    linkUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978&type=&job_hot_flag=&current=1&limit=10&functionCategory=7257443883902011707%2C7257445015411935545&tag=",
  },
  {
    key: "cpl2",
    img: require("@/assets/img/cp.png"),
    text: "产品类",
    desc: "在这里，你将深度接触各类业务，参与产品的调研、设计与落地，不断推动产品功能迭代。",
    linkUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978&type=&job_hot_flag=&current=1&limit=10&functionCategory=7257443374352959799&tag=",
  },
  {
    key: "xmgl",
    img: require("@/assets/img/xmgll.png"),
    text: "项目管理类",
    desc: "从项目设计到落地，合理安排每一个节点，精准把控每一个细节，推动目标向更完美迈进。",
    linkUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978&type=&job_hot_flag=&current=1&limit=10&functionCategory=7257442882856290619&tag==",
  },
  {
    key: "sjl",
    img: require("@/assets/img/sj.png"),
    text: "设计类",
    desc: "我们秉承蔚来设计基因，致力于打造纯粹、易用、有向往感的产品，为用户带来全感官的愉悦体验。",
    linkUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978&type=&job_hot_flag=&current=1&limit=10&functionCategory=7257444123311999292%2C7257443113022933305&tag=",
  },
];
const mobileHomePostDataDifference = [
  "szjs",
  "gcjsl",
  "znzz",
  "dldcjdq",
  "yhyfw",
  "cpl2",
  "xmgl",
  "znzc",
  "sjl",
].reduce((obj, key) => {
  obj[key] = {
    typeImage: require(`@/assets/img/school/${key}.png`),
  };
  return obj;
}, {});
// 手机端-主页-岗位
export const mobileHomePostData = homePostData.map((item) => ({
  ...item,
  ...mobileHomePostDataDifference[item.key],
  typeName: item.text,
  typeInfo: item.desc,
}));
// 校招详情页
export const campusBannerData = {
  title: "蔚来2025届秋季校园招聘\n正式启动",
  desc: "以热爱 致未来",
  deliverUrl:
    "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930669540509978%2C7383991896182343963&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=",
  image1:
    "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/%E6%A0%A1%E6%8B%9B%E9%9D%A2%E5%90%91%E4%BA%BA%E7%BE%A4.jpg?imageView2/interlace/1",
  image2:
    "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/%E6%A0%A1%E6%8B%9B%E5%B2%97%E4%BD%8D%E6%96%B9%E5%90%91.jpg?imageView2/interlace/1",
};
// 手机端-校园招聘详情图片
export const campusDetailImages = [
  "https://cdn-public.nio.com/nioappadmin/PiDIL6cNEGQ0gt42FZ1iN/e1a8b5bd-9ccf-4bbe-a24f-8c47d16759d4.jpg",
  "https://cdn-public.nio.com/nioappadmin/R7pOeVZHhyBHGX8R2CxPD/a5aad095-7e64-49ff-8664-9a3f4f21a81c.jpg",
  "https://cdn-public.nio.com/nioappadmin/3ePucCUWTzEYbmwB2OE9Z/ba05f49a-d438-4856-8e6d-84f9cc3fe41c.jpg",
];
export const questionAndAnswerTitle = {
  title: `蔚来2025届校园招聘Q&A`,
  desc: ``,
};
// QA
export const questionAndAnswer = [
  {
    typeName: "关于简历投递",
    list: [
      {
        question: "Q：此次校招对象面向哪些同学？",
        answer:
          "A：此此次校招针对2025届毕业生，即2024年9月至2025年8月期间毕业的同学。<br>*中国大陆地区以毕业证为准，非中国大陆地区以学位证为准，且最高学历毕业后无全职工作经验。",
      },
      {
        question: "Q：此次校招整体时间线是什么样呢？",
        answer:
          "A：简历投递：7月25日 - 10月31日<br>笔试/测评：7月31日起发放<br>面试：8月上旬开始<br>Offer发放：9月下旬开始",
      },
      {
        question: "Q：此次校招，每人可以投递几个岗位呢？",
        answer:
          "<p>A1：蔚来2025届校园招聘项目每位同学共计可以投递三个岗位。</p><p>A2：蔚来2025届区域公司校园招聘项目每位同学共计可以投递三个岗位。<br> <b>蔚来2025届校园招聘项目</b>与<b>蔚来区域公司校园招聘项目</b>不共用岗位投递次数，如两项目均投递共可投递六个岗位。<br> 如果你投递的岗位未被录用，HR 有可能会根据你的简历情况，再次邀请你进入其他岗位的校招流程。你的申请岗位及招聘进度会随之更新，请留意相关邮件及短信通知。</p>",
      },
      {
        question:
          "Q：如果此前已经投递了实习或社招岗位，还可以投递此次校招岗位吗？",
        answer:
          "<p>A：可以。如之前投递的实习或社招岗位在流程中，并出现排在校招岗位之前的情况，请发送邮件至<span style='color:#00b3be;text-decoration:underline;'>campus@nio.com</span>，说明你的姓名、联系方式及希望终止投递的岗位，我们将停止该岗位流程，同时进行下一校招岗位的流转。</p>",
      },
      {
        question: "Q：此次校招对学历和所学专业有限制吗？",
        answer:
          "A：不同的岗位对人才需求有所不同，有关学历和专业的要求都会在具体的岗位描述中呈现，符合要求的同学都可以投递相应岗位。同时也建议大家在投递前能仔细阅读岗位要求，精准投递。",
      },
      {
        question: "Q：岗位投递成功后，还可以修改投递岗位或调整岗位顺序吗？",
        answer:
          "<p>A：岗位一旦投递，不可以修改投递岗位或调整岗位顺序，但仍可发送邮件至 <span style='color:#00b3be;text-decoration:underline;'>campus@nio.com</span> 终止当前岗位流程。</p>",
      },
      {
        question: "Q：岗位投递成功后，还可以修改简历内容吗？",
        answer:
          "<p>A：如需修改简历内容，可登录蔚来校园招聘官网(<span style='color:#00b3be;text-decoration:underline;'>campus.nio.com</span>)，进入【个人中心-应聘记录】模块进行修改。已投递岗位不会展示更新后的简历内容；修改简历后投递的岗位，会展示更新后的简历内容。</p>",
      },
      {
        question: "Q：不同的岗位投递可以上传不同的简历吗？",
        answer:
          "A：可以，建议大家充分阅读岗位要求，准备和岗位关联度较大、且能体现个人竞争力的简历。",
      },

      {
        question: "Q：如何了解我的招聘进展？",
        answer:
          "<p>A：登录蔚来校园招聘官网(<span style='color:#00b3be;text-decoration:underline;'>campus.nio.com</span>)，页面右上角【应聘记录】可实时查看招聘进展。</p>",
      },
      {
        question:
          "Q：如果简历状态已经停留在某一个阶段很久了，说明流程已经结束了吗？",
        answer:
          "A：只有简历状态显示【流程终止】说明流程结束，其他状态请耐心等待后续通知。",
      },
      {
        question: "Q：如果简历状态显示【流程终止】，说明流程已经结束了吗？",
        answer:
          "A：是的，说明你在当前投递的岗位下暂无后续安排，你可以继续关注其他岗位。",
      },
      {
        question: "Q：我是在职实习生，我可以投递校招岗位吗？",
        answer:
          "<p>A：在职实习生可通过内部渠道投递简历，详情请通过飞书【星探小姐姐】获取具体操作流程。<br>已离职实习生可以直接通过校招官网(<span style='color:#00b3be;text-decoration:underline;'>campus.nio.com</span>)投递校招岗位，如无法投递，请发送邮件至至 <span style='color:#00b3be;text-decoration:underline;'>campus@nio.com</span> ，并且标注你的姓名、联系方式及离职日期，我们将及时处理。</p>",
      },
      {
        question:
          "Q：区域校园招募项目中职位前缀为“校招正式”和“校招实习”的岗位有什么区别？",
        answer:
          "<p>A：职位前缀为“校招正式”的岗位：面试通过将会直接获得校招正式 Offer；职位前缀为“校招实习”的岗位：面试通过后会先以实习生身份入职，提前体验职场、参与培训后，再通过转正述职/答辩的方式获取校招正式 Offer。<br>两类岗位前序的面试流程及难度会有差异，具体以投递后收到的短信和邮件通知为准。</p>",
      },
      {
        question: "Q：我是24届及以前的同学，可以投递此次校招项目吗？",
        answer: "A：24届及以前的同学建议关注社招岗位。",
      },
    ],
  },
  {
    typeName: "关于笔试/测评",
    list: [
      {
        question: "Q：此次校招投递通道中所有岗位都会安排测评/笔试吗？",
        answer:
          "A：所有岗位都会安排测评/笔试。如简历评估通过，我们会根据岗位类型安排笔试/测评，请大家注意查收邮件。",
      },
      {
        question: "Q：笔试/测评采取什么形式？什么时候启动？",
        answer:
          "A：采取在线笔试/测评的形式，如前序评估通过，第一批笔试/测评将在7月31日后发放，后续笔试/测评将会每周滚动发放。笔试/测评前将会发送相关邀请，请耐心等待邮件及短信通知。<br>注：周末及法定节假日期间不安排笔试/测评，具体安排请以收到的通知为准；试题均需要在72小时内完成作答。",
      },
      {
        question: "Q：错过此次笔试/测评，还能再参加吗？",
        answer:
          "A：笔试/测评有效期均为72小时，请大家在规定时间内参加，若错过则无补测机会。",
      },
      {
        question: "Q：我收到了多个测评，都需要参加吗？",
        answer:
          "A：是的，笔试内容会根据投递岗位要求不同有所区别，需要完成对应岗位的笔试。",
      },
      {
        question: "Q：测评/笔试之后，为什么简历状态一直为【笔试中】",
        answer:
          "A：显示【笔试中】进度，则表明我们正在对笔试/测评结果进行评估，请耐心等待后续结果。",
      },
    ],
  },
  {
    typeName: "关于面试",
    list: [
      {
        question:
          "Q：我的简历状态显示【面试中】，但却未收到任何通知，是什么原因？",
        answer:
          "A：显示【面试中】表示简历已经通过测评或笔试，后续会有HR通知你面试，请留意邮件及短信通知。",
      },
      {
        question: "Q：我错过了HR的电话怎么办？",
        answer:
          "A：不用担心，如果错过HR首次来电，HR会再次联系你，请务必留意近期来电。",
      },
      {
        question: "Q：我想调整面试时间，应该联系谁呢？",
        answer:
          "<p>A：请尽可能按时参与面试。如果临时有事或因不可抗力无法参加，请联系面试邀请邮件中HR的联系方式说明情况，并让HR协助修改。若因特殊情况无法联系上HR，可发邮件至 <span style='color:#00b3be;text-decoration:underline;'>campus@nio.com</span>，说明情况，我们会协助处理。【邮件主题】调整面试时间 【邮件内容】至少包含个人姓名，手机号，投递岗位名称，需要修改的面试时间和可面试的时间段。</p>",
      },
      {
        question: "Q：一共有几轮面试？",
        answer: `A：不同岗位的面试轮次设置不同，通常为3-5轮，如果需要知道具体岗位的面试安排，可以在面试阶段询问对应的HR。`,
      },
    ],
  },
  {
    typeName: "关于内推",
    list: [
      {
        question: "Q：此次校招可以内推吗？参加内推有什么作用？",
        answer:
          "A：可以找到你熟悉的蔚来员工参与内推，所有岗位均可参加内推。若通过内推投递校招岗位，你的简历将被优先推荐。更重要的是，你可以通过内推人更深入地了解蔚来和岗位所在的业务部门。",
      },
      {
        question: "Q：怎样获取内推机会？",
        answer:
          "A：你可以寻找周围的蔚来员工、蔚来校园大使，也可通过各类社群及社交平台等渠道，获取校招内推链接/内推码，投递成功即代表内推成功。",
      },
      {
        question: "Q：投递时没有填写内推码，之后还能补吗？",
        answer: "A：投递之后不可以再补充或修改内推码。",
      },
      {
        question: "Q：校招内推可以获得额外投递机会吗？",
        answer:
          "A：本次校招每个校招项目每人可投递三个岗位，通过内推渠道投递不会增加投递次数。",
      },
      {
        question: "Q：为什么我使用内推码投递简历时，提示无法投递？",
        answer:
          "A：投递简历显示【无法投递】可能是由于你在过往的180天内存在内推投递记录，且简历进入过笔试、面试等环节。此情况下无法通过内推渠道投递简历。可以不使用内推码，在校招官网进行直接投递。",
      },
    ],
  },
].map((item, index) => ({ ...item, id: index + 1 + "" }));
// Hello Nioer
export const helloNioerList = [
  {
    id: "6",
    cardTitle: "追求极致的与众不同",
    cardContent: "从把单体壳带进赛事的拓荒者到见证辅助驾驶诞生的工程师",
    cardImage:
      "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_card_list6.png",
    detailsUrl: "#/student",
    cardType: "homeSeniorSay",
    detailList: [
      {
        cardType: "2",
        imageUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_card_details1.jpeg",
        detailsIndex: 0,
      },
      {
        cardType: "1",
        detailsWord:
          '<h1 style="font-size: 28px;text-align: center">追求极致的与众不同</h1></br><h3 style="text-align: center;font-size:18px">从把单体壳带进赛事的拓荒者到见证辅助驾驶诞生的工程师</h3>',
      },
      {
        cardType: "4",
        items: [
          { label: "姓名：", value: "Max" },
          { label: "车队：", value: "SJTU Racing team 上海交通大学赛车队" },
          { label: "岗位：", value: "智能驾驶算法主任工程师" },
          { value: "2017年Sparks" },
        ],
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q1：能为我们分享下当年在车队的故事吗？</p>
        <p>我曾于2013年参与大学生方程式比赛，并连续参与了两年。在当时，我们车队率先将单体壳和单缸机引入了国内赛事。我们采用的碳纤维单体壳和单缸引擎组合在国内独树一帜，外部几乎无可借鉴之处，只能通过自行测试和摸索来不断完善。<br>
        我们选择这一方案的原因有两个：首先，碳纤维单体壳在轻量化上具有明显的优势，且具备高强度和较好的安全性；其次，单缸引擎重量轻，选择的型号功率质量比很高。另外，单体壳的制作工艺极具挑战性，当时国内几乎无人涉足，因此我们决定挑战这个艰巨的任务。我们为自己设定了一个宏伟的目标，要达到世界顶尖赛车的水准，并取得令人瞩目的成绩。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/Yr6UegQMujwBz072TG_q3/a83ffc21-f2bb-4caa-81d4-2d938a8628d3.jpg",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q2：车队的经历给你最大的收获是什么？</p>
          <p>在车队的这段时间里，我频繁地经历面对挑战和克服挫折的过程。这种持续的积累让我对汽车行业和整个工程领域充满敬畏之心，并增强了我面对困难的耐心。<br>
          选择制造单体壳和单缸机是追求极致的与众不同，虽然面临着诸多未知的困难，我们也毫不后悔地迎难而上。我们走过了前人未曾涉足的领域，为后来者积累了丰富的经验，这正是我认为这段经历非常有意义的地方。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/DovGyPa07zmYbe3BiBsQ8/cf0c8eef-6a0b-4d2c-a6c2-456c3ecd4f03.jpg",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q3：为什么选择加入蔚来？</p>
        <p>我之所以选择加入蔚来从事自动驾驶算法开发，实际上也是在追求独特的极致。因为在当时，国内几乎没有一家公司在自研完整的自动驾驶方案。在没有任何相关的成熟经验借鉴的情况下，蔚来采用了市面上最新的硬件方案，首次尝试集成；同时，蔚来非常重视用户体验，需要适应多种复杂场景，这对算法提出了更高的要求。然而，许多优秀的同事聚到一起，相互理解，共同追求同一个目标，一次又一次地尝试，探索着前所未有的领域。终于在2018年左右，辅助驾驶功能成功上线，实现了最初的目标。之后，我们在2020年推出了NOP领航辅助驾驶功能，也是国内首批交付给用户的智能驾驶产品。<br>
        当时我深感如果我们团队的人都无法想到最佳方案，那市场上一定也不会有！</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/53D9qTKC2ulbsam04XVJl/e8a37a9d-d439-4952-98da-c3d654f9eaad.jpg",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q4：在蔚来工作是什么样的体验？</p>
        <p>首先，我感觉蔚来的工作环境非常清晰透明，对年轻人十分友好。身边有经验丰富的同事给予了我许多关怀与帮助，并提供了很多锻炼的机会。我在工作经历中碰巧解决的问题也属于行业的难题，这使得我的经验与能力都得到了明显的提升。斌哥经常会在公司和部门里分享他的想法，并耐心解答大家的疑惑，每次都让我受益匪浅。<br>
        从研发工程师的角度来看，我在蔚来学到了将工程思维与用户思维相结合，将技术真正转化为用户体验的提升。在蔚来工作的这几年里，我感觉自己思考的维度和角度都得到了很大的拓展，这些都是我在蔚来获得的独特机遇。我觉得能够加入蔚来是一种幸运。</p>`,
      },
    ],
  },
  {
    id: "180",
    parentId: "5",
    cardTitle: "我选择向上的道路",
    cardContent:
      "完成跨界小白到从店总的职场飞跃后，下一步我选择加入乐道继续挑战",
    cardImage:
      "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/HelloNiOer徐蓓蕾-1.jpg?imageView2/interlace/1",
    detailsUrl: "#/student",
    cardType: "homeSeniorSay",
    detailList: [
      {
        cardType: "4",
        imageUrl:
          "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/HelloNiOer徐蓓蕾-顶图.png",
        detailsIndex: 0,
      },
      {
        cardType: "1",
        detailsWord:
          '<h1 style="font-size: 28px;text-align: center">职业的下一阶段，我选择向上的道路</h1></br><h3 style="text-align: center;font-size:18px">完成跨界小白到从店总的职场飞跃后，下一步我选择加入乐道继续挑战</h3>',
      },
      {
        cardType: "4",
        items: [
          { label: "姓名：", value: "Bella" },
          { label: "岗位：", value: "乐道苏南区域公司总经理" },
          { value: "2018届Sparks" },
        ],
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold;font-size:1.1em">在蔚来，我快速成长，收获远超期待的硕果</p>
        <p>我是Bella，加入蔚来前是“学艺术的”，2018年我从南京艺术学院研究生毕业，选择Fellow作为我探索职业发展的起点，经过了和蔚来一起的5年旅途，我从一个新手小白开始，到现在成长为能够独当一面的乐道苏南区域公司的总经理，也是逐渐拥有了一些职业生涯的高光时刻。<br>
        其实刚入职的时候，我跟大家一样，还不太成熟，对自己后续的发展也比较迷茫，好在那个时候公司为我们这些应届入职的伙伴提供了非常完善的体系化培训，帮助我迅速找到了方向。我个人记忆最深的就是“超级充电宝”的培养项目，不仅有创始人亲自带教，还有公司中各个业务领域的大佬来给我们做分享，培训的内容也不只拘泥于怎么做好销售，还有通识技能的教导、逻辑思维的养成等，甚至还从易经延申，讲述了很多做人做事的方法和经验。这个培训迅速拓宽了我的视野，给我带来了飞跃式的进步，往近处看，我快速地明确了当下的工作应该如何去推进；往远处看，我清晰地了解了长期而正确的路是什么样子的。可以这么说，这个培训让我在职业生涯的伊始就掌握了更好的工作方法论，进一步帮助我在之后的实践中少走了很多弯路。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/HelloNiOer徐蓓蕾-1.jpg?imageView2/interlace/1",
      },
      {
        cardType: "1",
        detailsWord: `<p>入职的第三年，我成为了一名店长。面临下一步的发展，因为接受过公司系统化培训，我获得了去总部做销售运营的机会，这个职业变动帮助我接触更宽阔的全国市场，给予了我丰富的经验、全面的视野。因此再次回到一线的时候，有了从业务实践与全国大盘视角中提炼的经验，我能够快速上手更高要求的工作，这些都与我接受的这套培训体系不可分割。<br>
          一路走来，我觉得我能够较快的达到个人职业生涯的小高峰是离不开公司这个平台的。包容关爱的人才态度，让我这个“非专业”出身的小白也有机会、有时间去成长；耐心科学的培养机制，让我的思维以及处理问题的方式迅速有了质的飞跃；“真诚、关爱、远见、行动”的企业价值观，让我能够在保持自我特色的同时，更好地发挥个人优势、释放潜能，这些可能是我在别的公司无法企及的。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/HelloNiOer徐蓓蕾-2.jpg?imageView2/interlace/1",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold;font-size:1.1em">在乐道，你机会无限，提供更快的职业发展可能</p>
        <p>在激烈竞争的就业市场中，行业趋于成熟，对年轻的伙伴来说，这意味着他们的职业发展道路会更难走。在拥挤严峻的职业环境下，乐道作为一个全新的品牌是拥有巨大优势的，处在快速发展阶段的新品牌意味着它有足够的空间给年轻人来成长和探索。同时，乐道背靠蔚来，承载着公司的使命、愿景与战略，有着生而成熟的体系，这些都支持着乐道“黄埔军校”式的人才培养机制，年轻人经过这套体系的培养是能够获得更优势的职业竞争力。<br>
        在蔚来五年的工作中，我获得了非常好的体验。现在乐道苏南区域已经有3批伙伴入职了，每批伙伴来的第一天我一定会和他们一起吃饭，这是一件很小的事情，但我希望伙伴们能从细节开始，感受到乐道所延续的“真诚、关爱、远见、行动”的企业价值观，乐道希望每位加入的小伙伴能在更加开放、更加创新的舞台上更自如地发挥，让每位伙伴都成为更好的自己。<br>
        现在我们已经为乐道从各个行业里招募了一批非常顶尖的人才，这些伙伴中不仅有人深耕智能电动汽车领域多年，拥有丰富实践经验、深厚专业知识；也有很多伙伴有着非常优秀的跨行业履历，摩拳擦掌为品牌注入新的活力……现在加入乐道，就有和整个行业顶尖人才交流、碰撞、成长的宝贵机会。对于刚毕业的应届生来说，和行业前沿人才直接对话、一起成长的机会是非常难得的，在精英的团队中学习能够迅速帮助自己提升势能，完成蜕变。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/HelloNiOer徐蓓蕾-3.jpg?imageView2/interlace/1",
      },
    ],
  },
  {
    id: "181",
    cardTitle: "自动驾驶需要有终局的思考",
    cardContent: "业界最早批实现自动驾驶自研上路的核心研发人员",
    cardImage:
      "https://cdn-campus.nio.com/nio-campus-service/2022/08/17/tb_card_list181.jpg",
    detailsUrl: "#/student",
    cardType: "homeSeniorSay",
    detailList: [
      {
        cardType: "4",
        imageUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/08/19/tb_card_details99.jpeg",
        detailsIndex: 0,
      },
      {
        cardType: "1",
        detailsWord:
          '<h1 style="font-size: 28px;text-align: center">自动驾驶需要有终局思考，只关注实现短期功能对我来说没有意义</h1></br><h3 style="text-align: center;font-size:18px">业界最早批实现自动驾驶自研上路的核心研发人员</h3>',
      },
      {
        cardType: "4",
        items: [
          { label: "姓名：", value: "Blake" },
          { label: "岗位：", value: "智能驾驶 规控研发团队负责人" },
          { value: "业界最早批实现自动驾驶自研上路的核心研发人员" },
          { value: "国内首个自动驾驶决策规划领域课程主讲人" },
        ],
      },
      {
        cardType: "3",
        videoUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/08/17/tb_card_details104.mp4",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“也经历过找工作的过程，刷了一个月的题，从早上6点到晚上12点，头都挠破了”</p>
        <p>我来自甘肃酒泉，与其他同学成长环境略有不同的是，我有一个双胞胎哥哥。我们互相竞争、互相学习，一起考入了北京大学数学学院。我们的高考分数只相差3分，他的成绩稍高一些，但我在竞赛成绩上略胜一筹，基本上能达到全省第一的水平。我主修统计学，而他选择了纯数学。本科毕业后，我直接申请了博士学位，但同时也辅修了计算机专业。我希望能够进入工业领域，从事一些对社会有价值的应用性工作。在求职过程中，计算机技能是一个重要的门槛，虽然这不是我的专长领域，但我也通过努力刷题提升自己。刷题的过程虽然艰辛，但每当花几个小时把一道题研究清楚时，我都感觉非常有成就感。谈到做题，从小我和哥哥就是既竞争又互相帮助的关系。当我解出一道竞赛题后，他总能想出一个与我不同的解法。对我而言，总有一种必须超越对方的动力。但是，通过互相比较解法，我们也能相互学习，这对我们的成长都是有益的。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/fY-Zn0VHOU-qgSy5Cq73h/893f387a-b7f4-45ac-8b02-8a8cbae98954.jpg",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“我认为蔚来在自动驾驶领域，是从2到3的阶段”</p>
          <p>2015年，当自动驾驶技术的浪潮刚刚兴起时，我担任数据科学家近三个月后，便转投了公司新成立的自动驾驶部门。当时，所有的事情都需要从零开始，没有任何经验可供借鉴。这一阶段可以被描述为从0到1的过程，在这个阶段，我们成功使车辆在一个封闭的园区内实现了自动驾驶，成为了最早一批实现自主研发的自动驾驶技术上路的团队之一。到了2018年底，我加入了由Chris Urmson （前Waymo CTO）创办的自动驾驶明星团队 Aurora Innovation，负责数据驱动的决策规划算法，评估体系以及高级自动驾驶能力的研发。这标志着开启了我们从1到2的过渡阶段，我们的技术在某些功能场景下已经具备了泛化性，可以在特定的范围内良好运行。但随着规模的扩大，我们面临着商业化和量产的挑战。接下来进入了从2到3的阶段，我选择加入蔚来，是因为公司具有天然的优势，我们能够直接面向用户。在拥有足够大的产品保有量的情况下，我们能够与用户进行更深入的互动，将用户的反馈转化为真正的生产力，形成产品和研发之间良好的闭环互动。</p>`,
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“我问斌哥，如果真的只是干个一两年，搞得差不多就交付给用户就行了，那你不要来找我。”</p>
        <p>我选择加入蔚来的原因之一是因为公司有着非常长远和终局的考虑，我们致力于长期为用户出行带来价值。我们需要在5到10年的时间跨度内思考技术的发展，并搭建能够支撑这一目标的团队。只有在满足基本需求后，人们才会开始思考更长远的事情。对于那些有能力规划未来的公司来说，它们一定会走得更远。当被一些非技术原因驱使去做某些事情时，有时候会感到非常被动。蔚来处于一个非常健康的阶段，拥有远见和护城河，能够想得更远，让技术拥有主动性。我们希望将蔚来定义为不仅仅是车企，更是一家高科技企业，在多个领域都有所作为，造福社会，提升人们的出行体验。</p>`,
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“T型人才容易倒，我更希望你是一个π型人才”</p>
        <p>对于加入蔚来的校招生来说，我们更加看重你的独立思考能力和解决问题能力。虽然我的工作时间不算很长，但我能够观察到真正成功的人是那些懂得如何解决问题的人，而不仅仅是被动地接受任务、完成任务。我建议同学们成为一个π型人才，即除了技术上的纵向深度之外，还要具备对行业的横向理解。当你刚进入一个行业时，这条横线会迅速拓展和延伸。在技术领域上，比如软件开发，可以找到很多人来做同样的事情，但是如果你同时懂得汽车、电子或设计等领域，就会有额外的优势来支撑你在行业中的发展。</p>`,
      },
    ],
  },
  {
    id: "182",
    cardTitle: "希望你永远保持好奇心和同理心",
    cardContent: "首届蔚来校招Sparks，与公司共同成长",
    cardImage:
      "https://cdn-campus.nio.com/nio-campus-service/2022/08/17/tb_card_list182.jpeg",
    detailsUrl: "#/student",
    cardType: "homeSeniorSay",
    detailList: [
      {
        cardType: "4",
        imageUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/08/17/tb_card_details108.jpeg",
        detailsIndex: 0,
      },
      {
        cardType: "1",
        detailsWord:
          '<h1 style="font-size: 28px;text-align: center">产品经理是特别幸福有成就感的职位，希望你永远保持好奇心和同理心</h1></br><h3 style="text-align: center;font-size:18px">首届蔚来校招Sparks，与公司共同成长</h3>',
      },
      {
        cardType: "4",
        items: [
          { label: "姓名：", value: "Shirley" },
          { label: "岗位：", value: "产品体验 资深产品体验经理" },
          { value: "首届蔚来校招Sparks，与公司共同成长" },
          { value: "2020蔚来之星 | 2021蔚来成就奖" },
        ],
      },
      {
        cardType: "3",
        videoUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/08/17/tb_card_details110.mp4",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“我尝试过很多岗位，最终选择了产品经理”</p>
        <p>我是一个思虑较多的人，自大一起便开始思考毕业后的职业道路。因此，在大学期间，我以实习的形式尝试了我所感兴趣的各种职业，包括主持人、配音员、平面模特等等。 偶然的机会，我体验了产品经理这一岗位，将构思落实为切实可行的产品，并将其投入市场，并最终接受用户反馈的过程深深地吸引了我。产品经理的成功与否、以及产品质量的优劣是非常客观和真实的标准，产品一经推出，用户的反馈会直接而清晰地评价产品的表现。这些经历使我深信产品经理是一个充满幸福感和成就感的职业。加入蔚来后，我参与了数字座舱、导航、二代换电站等产品的定义工作，同时也见证了我们车机操作系统从Aspen到Alder再到Banyan的不断进步和迭代。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/4_zIvYt9Io1asbqXkcI19/205bbfa6-46e0-4dc1-91bc-7ea67d0875c1.jpg",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“当时我本着负责的态度劝过他，但是用户说赛车场和车手证他都有”</p>
          <p>在2016年的上海车展上，我有幸体验了一段时间的蔚来顾问实习工作。其中最令人兴奋的经历是成功销售出了一辆EP9超级跑车。当时有一个用户对我们正在销售的ES8并不感兴趣，于是我向他介绍了EP9。令我意外的是，他当场决定购买一辆。 我感到非常震惊，立即提醒他再三考虑，包括该车148万美元售价、只能在赛道上行驶，以及需要具备赛车手证才能够驾驶等限制。然而，他认为这些都在他的承受范围之内，并最终决定购买EP9。在这次车展上，除了我们的工作人员外，创始人斌哥和力洪也全程参与，为用户介绍我们的品牌、价值理念和产品优势。在中午和晚上，我们还会一起在后台的小房间里坐成一圈吃盒饭。我从未见过其他企业的创始人能够像他们一样平易近人，这让我深刻感受到蔚来就是一个友好的大家庭。</p>`,
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“在互联网大厂有可能会加入到随时会叫停的业务，也有可能在成熟的业务中负责多级页面下很小的项目”</p>
        <p>我身边有许多朋友都在互联网头部企业担任产品经理，但各公司的文化和价值观却各不相同，有些公司偏向创新驱动、有些则更注重运营或商业驱动。相比之下，我们在产品开发方面的目标和理念有所不同，我们坚持以用户为中心，从用户体验出发，致力于做对用户有价值的事情。因此，在产品定义方面，我们需要更深入地考虑用户需求，进行更长期的思考。在蔚来工作虽然不轻松，但是工作氛围相对比较轻松，部门之间没有太多壁垒，信息也较容易互通、同步。相对于其他大公司，我认为蔚来最大优势在于其平台和机会。来到这样一个新兴的领域，越早进入优势也会越大；同时蔚来对于新人的信任度较高，给予校招生更多的自主权，让他们有机会成长，尝试并承担独立项目。</p>`,
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“曾经被用户批评到emo了，我们鼓起勇气飞到用户所在的城市去沟通”</p>
        <p>在公司早期，我们的产品确实存在许多不完善之处，很多用户会在各种渠道上对我们的产品体验发表意见。我当时是通过各种渠道收集信息，找到了一群对我们的产品体验反馈积极的用户。于是，我们几个小伙伴决定挺身而出，拎着箱子飞往广州、深圳、北京、青岛等城市，与这些用户面对面交流，听取他们对蔚来的意见和建议。令人难忘的是，有一位用户看见我们疲倦不堪，原本准备发表批评，但见我们如此辛苦，便招待我们吃海鲜。在享用美食的同时，他分享了对蔚来的看法，自己的用车体验和对我们工作的感受。交流结束后，他对蔚来产生了浓厚的兴趣，甚至从青岛来到了上海，最终成为了我们产品体验团队的一员。</p>`,
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“2019年能挺过来，用户对我们非常信任是重要的原因”</p>
        <p>我们拥有一个完善的用户体验闭环机制，通过多种渠道积极倾听用户的声音，像用户的微信群，NIO App上的群和帖子，以及通过Nomi收到的用户反馈，我们还定期给用户打电话等。我记得有一次，我们邀请一位新入职的同学参加用户体验沙龙，这种面对面交流的形式让从传统主机厂过来的他感到非常震惊。他提到以前很多用户的反馈到4S店后就此中断，很难和车企建立联系。在2019年低谷时期，我反而是对我们公司充满信心。我们看到很多车主用户愿意自发利用自身资源帮助蔚来做宣传，共同帮助我们度过了艰难的时刻。2017年车型刚出来的时候，我们收到了很多言辞激烈的反馈，但到了2019年，用户反而以更温和的态度支持我们。我深信，无论外界反馈如何，坚定做正确的事情就会获得回报。</p>`,
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“希望你可以永远充满好奇心和同理心”</p>
        <p>我们部门对于人才的需求最核心的是，你要对这个世界充满好奇心和同理心，能够换位思考，深入用户研究和用户洞察。其次是逻辑思维能力，能够提出切实可行的方案，具备辩证思考的能力，勇于挑战常规、勇于突破。蔚来会为年轻人提供许多锻炼的平台和机会，如果你是一个不甘平庸、喜欢挑战的人，蔚来将会非常适合你。</p>`,
      },
    ],
  },
  {
    id: "183",
    cardTitle: "加入蔚来拓宽认知边界",
    cardContent: "Linux内核华人Top100贡献者",
    cardImage:
      "https://cdn-campus.nio.com/nio-campus-service/2022/08/17/tb_card_list183.jpeg",
    detailsUrl: "#/student",
    cardType: "homeSeniorSay",
    detailList: [
      {
        cardType: "4",
        imageUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/08/19/tb_card_details119.jpeg",
        detailsIndex: 0,
      },
      {
        cardType: "1",
        detailsWord:
          '<h1 style="font-size: 28px;text-align: center">欢迎更多软件背景的同学，加入蔚来拓宽认知边界</h1></br><h3 style="text-align: center;font-size:18px">Linux内核华人Top100贡献者</h3>',
      },
      {
        cardType: "4",
        items: [
          { label: "姓名：", value: "Neil" },
          { label: "岗位：", value: "数字座舱 信息娱乐应用软件研发团队负责人" },
          { value: "Linux内核华人Top100贡献者" },
          { value: "蔚来校招笔试委员会成员" },
        ],
      },
      {
        cardType: "3",
        videoUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/08/17/tb_card_details122.mp4",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“我选择加入蔚来，是基于天时、地利、人和”</p>
        <p>我叫Neil，来自数字座舱部门，毕业于浙江大学。在芯片行业工作了9年后，于2014年加入互联网公司，直到2021年加入了蔚来。 我其实对跳槽比较谨慎，在加入蔚来前做了很长时间的思考。从“天时”来讲，新能源汽车行业有着非常明显的向上趋势，同时在政策支持、技术积累、市场格局上都有着很大的发展优势；“地利”代表的是这个企业在行业中所处的位置怎样，而蔚来这家企业通过长期思考与提前布局，已经在新能源汽车行业中占有先发优势；最后一点是“人和”，它体现在这个企业的文化价值观能不能把优秀的人聚拢在一起，发挥这个集体最大的能量。 综合这三方面的考虑，我最终于2021年7月份，决定加入蔚来，希望能与这些志同道合的伙伴一起挑战，共同成长。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/vQAIjbx5YV0S82F5K8skX/22f14ce5-2976-4bbf-831f-e2889b666fc4.jpg",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“我们通过远程对车内软件进行升级，让用户持续保持对车的新鲜感”</p>
          <p>“软件定义汽车”这个概念最近在汽车行业中非常流行，我们也非常期待人工智能、算法、软件开发等领域的优秀人才加入我们。传统汽车上市交付给用户以后，所有的功能就已经完全决定了，用户从4S店买到的车是什么样子，它以后就是什么样子。但是对于新能源车来说，因为有了OTA的能力，可以通过远程对车端软件进行升级。当我们打造了一整套领先的电子架构时，一辆车在它的全生命周期都可以通过软件和功能的迭代，让用户保持新鲜感，用户也可以和也和企业一起分享最新的技术发展。</p>`,
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“蔚来笔试在题型设计上结合了日常的工作场景，希望同学们对行业有提前了解”</p>
        <p>我是校招的笔委会成员，我们会以一个菱形的结构安排试题，偏简单的以及偏难的占少部分，中间大部分是一些中等难度的题型，通过这样的题型设计让大家更有区分度。在题型设计上我们会考虑跟工作相结合的部分，所以希望同学们对于新能源汽车行业有一定的提前了解与规划。 想要高分通过笔试，代码能力和基础知识是比较重要的，可以先在牛客网或者Leetcode等网站刷一刷题库；除此之外，我们也希望同学有一些项目课题经验，能把学到的知识融入真实的场景中，通过实践来不断巩固理论知识。</p>`,
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">“有硬件背景这可能会成为一个加分项，但不是一个必须项”</p>
        <p>在智能座舱的工作场景中，首先是智能的中控大屏，中控大屏上面运行的是安卓系统，在软件开发层面，这与互联网行业或者手机行业的工作逻辑是类似的；其次是仪表盘，我们可能会采用一些微内核的系统，因此，熟悉Linux系统、QNX系统以及安卓系统的同学都是我们的目标人才；蔚来的座舱中还有一个可爱的Nomi，人工智能、语音算法类的同学也可以在这里大展身手。 有硬件背景这可能会成为一个加分项，但不是一个必须项。我们会提供一些工具和相应的培训让新同学快速适应与硬件接触的环境，不断地拓宽认知边界、拥有更多能力和多维度的视角，这对于纯软件背景的同学也是一种很重要的财富。</p>`,
      },
    ],
  },
  {
    id: "7",
    cardTitle: "在蔚来，感觉回到了赛场",
    cardContent: "从超高执行力的队长学姐到把交付视</br>作比赛的项目经理。",
    cardImage:
      "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_card_list7.png",
    detailsUrl: "#/student",
    cardType: "homeSeniorSay",
    detailList: [
      {
        cardType: "2",
        imageUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_card_details12.jpeg",
        detailsIndex: 0,
      },
      {
        cardType: "1",
        detailsWord:
          '<h1 style="font-size: 28px;text-align: center">在蔚来，感觉回到了赛场</h1></br><h3 style="text-align: center;font-size:18px">从超高执行力的队长学姐到把交付视作比赛的项目经理</h3>',
      },
      {
        cardType: "4",
        items: [
          { label: "姓名：", value: "王学姐" },
          { label: "车队：", value: "ZJU Horizon 浙江大学启真方程式赛车队" },
          { label: "岗位：", value: "整车应用软件资深项目经理" },
          { value: "2019年Sparks" },
        ],
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q1：能为我们分享下当年在车队的故事吗？</p>
        <p>我曾经在浙江大学启真方程式赛车队呆了三年。我印象比较深的是作为车队队长参赛的那年，车队在临参赛前，将零件拆下来拿去电镀，结果却碰上快递受限，零件无法及时寄回。为了比赛不受影响，我们决定自己找回零件。我们打了一天电话，才摸清快递的链路，最终锁定零件所在快递仓库，半夜找老师开车去郊区仓库翻找出来，最终为团队顺利参赛节省了一天的时间。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/Hnelc5xHsfBtgoT-D8hea/bcb62467-ffeb-4c0e-97b4-57c80a52f66f.jpg",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q2：车队的经历给你最大的收获是什么？</p>
          <p>比赛最大的收获是能和团队的伙伴为了一个共同的目标，没日没夜地一起拼搏、一起奋斗，最后看着自己亲手打造的赛车上赛场时，会产生巨大的成就感。而且在车队这三年可以说是我成长最快的时期，不仅是因为作为队长和副队长的经历让我感觉到了责任感，学习到了如何在团体里去承担责任；同时，车队的造车时间比较紧张，很多决策都要立刻决定、立刻实施，这很大程度上锻炼了我的执行力。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/MdL_o5y_xaxP_-EK2T3Re/1b5b529a-d1e0-4fce-a22b-afc1472401c3.jpg",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q3：你为什么选择加入蔚来？</p>
        <p>蔚来更注重培养学生的思维方式与问题解决能力，把每一位学生作为一个专业的工程师看待，给予每一位学生纯粹的尊重。当时我就喜欢上了蔚来的企业文化氛围。<br>
        在车队的经历让我觉得自己是喜欢并适合项目经理的工作的。车队的队长工作其实有点类似项目经理的工作，要协调一群工程师分工合作、在指定的时间范围内完成任务、交付产品。每当在交付前去检视的时候，我都会感觉像是在给赛车做比赛前的最终调整，就好像回到了大学生方程式的赛场。而那种交付完成后的成就感，不亚于看见自己亲手打造的赛车在赛场上驰骋。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/HYDULVCGPlbStmH77XY3X/a4853f04-daf8-4997-a958-17f90e2e97da.jpg",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q4：在蔚来工作是什么样的体验？</p>
        <p>在蔚来的团队氛围很好，跨部门协作可以直接联系，不需要层层汇报报备；遇到紧急重要的事情，大家会主动突破职责界限，主动分享见解，一起承担工作；在这样的环境下，创新的氛围很浓郁，大家都敢于试错。同时我认为蔚来是个年轻的企业，没有历史的包袱，让我们可以轻装上阵，在没有人涉足的领域自由生长。</p>`,
      },
    ],
  },
  {
    id: "8",
    cardTitle: "在热爱的领域持续发光",
    cardContent: "我一直坚持自己热爱的空气动力学，所</br>以我来到了蔚来。",
    cardImage:
      "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_card_list8.png",
    detailsUrl: "#/student",
    cardType: "homeSeniorSay",
    detailList: [
      {
        cardType: "2",
        imageUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_card_details23.png",
        detailsIndex: 0,
      },
      {
        cardType: "1",
        detailsWord:
          '<h1 style="font-size: 28px;text-align: center">在热爱的领域持续发光</h1></br><h3 style="text-align: center;font-size:18px">我一直坚持自己热爱的空气动力学，所以我来到了蔚来</h3>',
      },
      {
        cardType: "4",
        items: [
          { label: "姓名：", value: "Frank" },
          { label: "车队：", value: "WUTE/UBRacing/Imperial Racing Green" },
          { label: "岗位：", value: "机械工程高级工程师" },
          { value: "2020年Sparks" },
        ],
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q1：能为我们分享下当年在车队的经历与收获吗？</p>
        <p>我叫Frank，曾经是FSEC 2018、2019、2020的三届裁判。在就读武汉理工大学的时候是车队的运营负责人，后来在伯明翰交换时，在车队里做空气动力学设计的工作，在帝国理工读硕士时是空气动力负责人。<br>
        我从小就比较喜欢车，高考前听说了大学生方程式赛事，就确立好以此为目标，选择一所能让我一展身手的大学。加入车队这件事本身并不是很特别，但是我觉得付出全部精力并得到回报的过程是特别有意义的。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/BrgDv6GRl6J_3m2VF5jhT/7c58bd35-7b72-475b-8a3e-6b6d1d3bdd52.png",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q2：你为什么选择加入蔚来？</p>
          <p>我和蔚来的缘分相当久。在武汉理工大学读本科的第一年，有蔚来的伙伴找到我问是否愿意加入FE车队实习，这是国际汽联首届电动方程式大赛，当时我认为自己不够优秀，所以拒绝了这个机会，但在那一年的比赛中蔚来夺冠了。这次的经历给我带来相当大的遗憾，所以决定毕业后一定要去蔚来工作。<br>
          NIO取意A New Day，同时表达了蔚来追求美好明天和蔚蓝天空、为用户创造愉悦生活方式的愿景，这种理念是非常吸引我的。其次，我想加入一家在国际市场有竞争力的公司。在蔚来工作就会不自觉地与行业最强的对手比较，自己也想要做到更好更优秀，因为在这里真的有机会挑战自我，做出更厉害的产品。</p>`,
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q3：你在车队中的丰富经历，是否对加入蔚来产生了帮助？</p>
        <p>车队的同学很多在毕业后进入了各种车企，而且从事的也都是和车队经历相关的职位。如果你有一个清晰的规划，在就业方向上与在车队中扮演的角色保持一致，那么这段经历在你的求职过程中是很有优势的，因为这是一段在课本上学不到的宝贵经历。<br>
        在学业的压力下，如果车队的成员们想要坚持下来，就需要对团队与赛车保有极大的热爱，这份热爱也将延续在你的事业和工作中，同时你也会在这里交到真正的朋友——因为你们有相同的目标，相同的经历，相同的热爱。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/wZFoiluYC-OX42OAFoKIL/b119030c-36a3-4c00-897b-a0aed6d4c385.png",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q4：在蔚来有什么不一样的工作体验？</p>
        <p>我目前的工作内容是多款车型的空气动力学性能开发工作，在ET7上我们做出了当时全世界量产车第二低的风阻系数——0.208；从入职至今，参与了EC6，ET7，ET5开发的同时，我的能力也在逐步提升。<br>
        蔚来和车队有着相似却又不同的工作方式。不同之处显而易见，在蔚来的团队中产品开发、项目管理等方法更科学；在开发过程中仿真、实验、验证等资源也远比在车队工作时丰富得多，在车队中可望而又不可即的风洞试验在工作中也是家常便饭。而相似之处则让我更喜欢这里——团队的目标都是造出性能最好的车，团队中有一群热爱且纯粹的伙伴。<br>
        值得一提的是，蔚来的同事中有不少人都在大学生方程式大赛锻炼过；单说空气动力学组除我之外，还有来自斯图加特大学绿队、浙江大学车队的同事。这样的工作环境相对更年轻，更有活力，相比在学校和车队，这里有更多更自由的研究方向，年轻人的想法也会得到充分尊重，并让你有机会付诸实践。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/YIMhrRbBKQdEqcrFaFdUU/81631e8e-e047-41c2-8751-ce55dddb3c60.jpg",
      },
    ],
  },
  {
    id: "9",
    cardTitle: "做用户最信任的朋友",
    cardContent:
      "从车队研发到蔚来顾问，在与用户成</br>为朋友的过程中找到自身价值。",
    cardImage:
      "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_card_list9.png",
    detailsUrl: "#/student",
    cardType: "homeSeniorSay",
    detailList: [
      {
        cardType: "2",
        imageUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_card_details34.png",
        detailsIndex: 0,
      },
      {
        cardType: "1",
        detailsWord:
          '<h1 style="font-size: 28px;text-align: center">做用户最信任的朋友</h1></br><h3 style="text-align: center;font-size:18px">FSEC 2017冠军队长的蔚来之旅：“从车队后端研发到蔚来前端顾问，我在与用户成为朋友的过程中找到了自身的价值。”</h3>',
      },
      {
        cardType: "4",
        items: [
          { label: "姓名：", value: "Ohlins" },
          { label: "车队：", value: "东风HUAT车队" },
          { label: "岗位：", value: "区域公司上海大宁国际商业广场店总" },
          { value: "2018年Sparks" },
        ],
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q1：能为我们分享下当年在车队的经历与收获吗？</p>
        <p>我是Ohlins，曾经是湖北汽车工业学院车队FSEC2017冠军队长，也曾在蔚来的赞助支持下，带领车队赴日本参加了国际赛事。在日本比赛时还有件特别有趣的事儿，当时赛车（燃油车）由于飘洋过海受海水腐蚀影响，送到日本后发动机打不着，车子无法启动，我们在人生地不熟的赛场花费了好大精力才解决问题，最后发动机点火的那一瞬间，我们就像看到奥运圣火被自己亲手点燃一样兴奋。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/wyaaPGqR1aOwC6ExfvpyC/8ab2765f-3c25-45e8-846f-2e4b1efb60e4.png",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q2：在蔚来的工作体验如何？</p>
        <p>我现在从事岗位是蔚来顾问（Fellow），核心的业务就是完成用户发展。这个岗位类似销售顾问，我们的核心目标是提升用户满意度。刚开始工作的时候，我常常找不到自身的价值。但是随着我对于蔚来的商业模式、用户关系以及企业价值观越来越深刻的理解，我就越来越明白应该用真诚去打动用户的心，最终促成其交易，以及用户与蔚来之间更长久、更紧密的链接。我们始终坚持“交朋友”才是最终目的，成交的订单是我们在交朋友的过程中产生的“附加值”。在这种工作目标以及氛围之下，我很快找到了自己作为一名Fellow的乐趣和价值。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/Mz5OKS8-K5rK58G7ky_j0/36ae525e-5707-4715-b545-984978e802b2.png",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q3：你为什么选择加入蔚来？</p>
        <p>我是在车队经历中，发现自己的能力是适配蔚来顾问岗位的。在车队比赛中我负责驾驶和工程设计，同时也负责商业展示。而只有在商业展示的时候，我才更能发挥出自己的优势。相比于研发，我更喜欢和人打交道。所以毕业之后并没有选择偏后端的研发岗位，而是在前端做自己喜爱和擅长的与用户沟通的工作。<br>
        从事工程研发，对于数字的敏感性、零件之间配合的精确性是要求极高的。而与用户打交道的时候，除了要有比较严谨的逻辑以外，也需要具备更多感性的特质，比如与用户共情、具备同理心等方面，则是我更擅长的。</p>`,
      },
      {
        cardType: "2",
        imageUrl:
          "https://cdn-public.nio.com/nioappadmin/EcIgx_Bgg8nJGr1OE9cuj/e7251fe2-aeb3-4fdf-b6ba-92b4ede4d5f2.png",
      },
      {
        cardType: "1",
        detailsWord: `<p style="font-weight:bold">Q4：你认为在蔚来做销售有什么不同？</p>
        <p>与传统汽车4S店不同，蔚来称所有的车主为“用户”，而不是“客户”。因为蔚来是一家用户企业，我们遵循用户至上的原则，非常重视用户体验以及用户满意度。蔚来顾问与传统意义的销售也不同，更像是用户的朋友，不求短期利益，而重在建立长期关系，这是一种从用户到朋友的相处模式。<br>
        我认为和用户交朋友的整个过程是非常有意义的。一开始是陌生人，通过不断的沟通交流、彼此熟悉、建立情感和信任。在我的工作中有这样一段比较有趣的经历，有一个用户和我因蔚来而结识，聊天后发现我们都有着共同的FSEC经历，那一瞬间感觉像是自己见到了多年未见的“老朋友”，与有共同话题的用户交流也让我更加充满干劲，所以我们很快就成为了“真朋友”。</p>`,
      },
    ],
  },
];
export const helloNioerDetailMap = helloNioerList.reduce((obj, item) => {
  obj[item.id] = item.detailList;
  if (item.id === "6") obj["50"] = item.detailList;
  else if (item.id === "7") obj["61"] = item.detailList;
  else if (item.id === "8") obj["64"] = item.detailList;
  else if (item.id === "9") obj["65"] = item.detailList;
  return obj;
}, {});
// 乐道 - 我们正在招
export const onvoRecruitPositions = [
  {
    title: "乐道顾问",
    desc: "家庭用户的用车专业顾问",
    back: "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道顾问.png?imageView2/interlace/1",
    deliverUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=%E4%B9%90%E9%81%93%E9%A1%BE%E9%97%AE&category=&location=&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
  },
  {
    title: "交付运营",
    desc: "车辆交付的全流程管家",
    back: "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/交付运营.png?imageView2/interlace/1",
    deliverUrl:
      "https://nio.jobs.feishu.cn/campus/?keywords=%E4%BA%A4%E4%BB%98&category=&location=&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
  },
];
// 乐道-轮播
export const onvoSwipeList = [
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-1.jpg?imageView2/interlace/1",
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-2.jpg?imageView2/interlace/1",
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-3.jpg?imageView2/interlace/1",
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-4.jpg?imageView2/interlace/1",
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-5.jpg?imageView2/interlace/1",
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-6.jpg?imageView2/interlace/1",
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-7.jpg?imageView2/interlace/1",
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-8.jpg?imageView2/interlace/1",
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-9.jpg?imageView2/interlace/1",
  "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道轮播-10.jpg?imageView2/interlace/1",
];
