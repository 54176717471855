<template>
  <div style="padding-top: 90px; padding-bottom: 40px">
    <div class="swiperList">
      <div>
        <p style="
            font-size: 24px;
            color: rgba(4, 11, 41, 1);
            margin: 0 0 60px 0;
            text-align: center;
          ">
          Hello NIOer
        </p>
      </div>
      <div class="about">
        <div class="left_yuan" @click="cardPunt()">
          <i class="el-icon-arrow-left"></i>
        </div>
        <el-carousel ref="nop" :interval="4000" :loop="true" type="card" indicator-position="none" :autoplay="true"
          arrow="never" height="362px">
          <el-carousel-item class="swiperList_data" v-for="(item, index) in deviceList" :key="index">
            <div class="talkCard" :class="{ talkCardShadow: item.detailsUrl }" @click="goRoute(item)">
              <img :src="item.cardImage" />
              <div class="cardText">
                <p class="cardTitle">{{ item.cardTitle }}</p>
                <p class="cardInfo" v-html="item.cardContent"></p>
              </div>
            </div>
          </el-carousel-item>
          <div class="back_ground"></div>
        </el-carousel>
        <div class="right_yuan" @click="cardNext()">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helloNioerList } from "@/utils/config";
export default {
  name: "Header",
  data() {
    return {
      deviceList: helloNioerList,
    };
  },
  methods: {
    cardPunt() {
      this.$refs.nop.prev();
    },
    cardNext() {
      this.$refs.nop.next();
    },
    // 查看详情跳转
    goRoute(link) {
      if (link.detailsUrl) {
        window.open(
          link.detailsUrl +
          "?id=" +
          link.id +
          "&table=1"
        );
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped lang="less">
.swiperList {
  min-width: 1000px;
  margin: 0 auto;

  .about {
    position: relative;
  }
}

.right_yuan {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 38px;
  width: 38px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: rgba(31, 45, 61, 0.11);
  color: #fff;
  position: absolute;
  top: 50%;
  right: 26px;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
}

.left_yuan {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 38px;
  width: 38px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: rgba(31, 45, 61, 0.11);
  color: #fff;
  position: absolute;
  top: 50%;
  left: 16px;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
}

.right_yuan:hover {
  background-color: rgba(174, 174, 174, 1);
}

.left_yuan:hover {
  background-color: rgba(174, 174, 174, 1);
}

.talkCard {
  justify-content: space-between;
  position: relative;
  //height: 337px;
  //width: 248px;

  border-radius: 4px;

  margin-right: 16px;

  background-color: #fff;
  overflow: hidden;
}

.talkCardShadow {
  justify-content: space-between;
  position: relative;
  //width: 248px;

  border-radius: 4px;

  margin-right: 26px;

  background-color: #fff;
  overflow: hidden;
}

.talkCardShadow:hover {
  cursor: pointer;
}

.talkCard:last-child {
  margin-right: 0;
}

img {
  height: 240px;
  width: 100%;
  object-fit: cover;
}

.back_ground {
  width: 724px;
  height: 330px;
  position: absolute;
  z-index: 0;
  background: #ffffff;
  top: 0px;
  left: 0px;
}

/deep/ .el-icon-arrow-right {
  text-align: center;
  line-height: 38px;
}

/deep/ .el-icon-arrow-right:before {
  color: #41475e;
}

/deep/ .el-icon-arrow-left {
  text-align: center;
  line-height: 38px;
}

/deep/ .el-icon-arrow-left:before {
  color: #41475e;
}

/deep/ .el-carousel--horizontal {
  width: 724px;
  margin: 0px auto;
}

/deep/ .el-carousel__container {
  width: 701px;
  overflow: hidden;
  margin-left: 20px;
}

/deep/ .el-carousel__item.swiperList_data.el-carousel__item--card {
  //box-shadow: 0px 1px 10px rgba(174, 174, 174, 0.15);
  background-color: #ffffff;
  height: 330px;
  margin: 0px 10px;
  z-index: -1;
  text-align: left;
}

/deep/ .el-carousel__item.swiperList_data.el-carousel__item--card.is-in-stage {
  width: 321px;
  background-color: #ffffff;
  height: 336px;
  box-shadow: 0px 4px 40px rgba(174, 174, 174, 0.15);
  z-index: 1;
}

/deep/.el-carousel__item.swiperList_data.el-carousel__item--card.is-in-stage:first-child {
  z-index: 1;
}

/deep/.el-carousel__item.swiperList_data.el-carousel__item--card.is-in-stage {
  z-index: 1;
  box-shadow: 0px 1px 10px rgba(174, 174, 174, 0.15);
  background-color: #ffffff;
}

/deep/ .el-carousel__item.swiperList_data.is-active.el-carousel__item--card.is-in-stage {
  width: 321px;
  background-color: #ffffff;
  height: 336px;
  box-shadow: 0px 0px 40px rgba(174, 174, 174, 0.15);
  z-index: 2;
}

.cardText {
  .cardTitle {
    font-size: 16px;
    margin: 8px 16px;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #040b29;
  }

  .cardInfo {
    margin: 8px 16px;
    font-size: 12px;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #41475e;
    white-space: nowrap;
  }
}

// .btn {
// 	position: absolute;
// 	display: inline-block;
// 	width: 80px;
// 	height: 30px;
// 	line-height: 30px;
// 	right: 2%;
// 	bottom: 2%;
// 	cursor: pointer;
// 	border-radius: 3px;
// 	text-align: center;
// 	margin: 0 5px;
// 	color: #00b3be;
// 	border: 1px solid #00b3be;
// 	vertical-align: middle;
// 	font-size: 15px;
// }
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
