import request from "../utils/request";

// 首页暑期实习生展示
export function getIndexSummerIntern(params) {
  return request.postJson("/h/modelStudent", params);
}

// 获取招聘流程
export function getRecruitProcess(params) {
  return request.postXForm(
    "/recruitmentDynamics/recruitmentModuleFlow",
    params
  );
}

// 人才项目
export function getTelentProject(params) {
  return request.postFormData(
    "/TalentsRecruitmentController/showTalentsRecruitment/" + params
  );
}
// 主页-校招项目-大卡片
export function getHomeBigNotice() {
  return request.postFormData("h/cardList/homeSchool");
}
// 答疑专区
export function getQA() {
  return request.postFormData("summerEnrollment/mentoringExplain");
}

//访问网站来源接口
export function saveReferrer(params) {
  return request.postJson(
    "/tb/third/website/insertTBThirdWebsiteModel",
    params,
    { errorClose: false }
  );
}
// 大学生方程式学长学姐说
export function getDataTalk() {
  return request.postFormData("/h/cardList/formulaSeniorSay");
}
// 区域sparks
export function getcardList(params) {
  return request.postJson("/h/cardList", params, { errorClose: false });
}
// 时间线单独的接口
export function getcardFesc() {
  return request.postXForm("/h/cardList/fesc");
}
// 大学方程式的详情页
export function getfescpithy() {
  return request.postXForm("/h/cardList/webformula");
}
//蔚来2023届秋招正式批全面启动
export function getWebAutumnRecurit() {
  return request.postXForm("/h/cardList/webAutumnRecurit");
}
export function getWebPublicity() {
  return request.postXForm("/h/cardList/webPublicity");
}

// 秋季校园招聘研发提前批 pc
export function getWebformula() {
  return request.postXForm("/h/cardList/webforautumn");
}
// 移动端大学生方程式详情
export function getalentList() {
  return request.postXForm("/h/cardList/mobileformula");
}
// 秋季校园招聘研发提前批 mobile
export function getMobileformula() {
  return request.postXForm("/h/cardList/mobileforautumn");
}
export function getMobilePublicity() {
  return request.postXForm("/h/cardList/mobilePublicity");
}
//移动端蔚来2023届秋招正式批全面启动
export function getMobileAutumnRecurit() {
  return request.postXForm("/h/cardList/mobileAutumnRecurit");
}
// 移动端 NIO YOU详情
export function getMobilenio_u() {
  return request.postXForm("/h/cardList/mobilenio_u");
}
// PC NIO YOU详情
export function getWebnio_u() {
  return request.postXForm("/h/cardList/webnio_u");
}
export default {
  getIndexSummerIntern,
  getTelentProject,
  saveReferrer,
  getHomeBigNotice,
  getDataTalk,
  getcardList,
  getcardFesc,
  getfescpithy,
  getalentList,
  getMobileformula,
  getWebformula,
};
