<template>
  <div class="home">
    <VideoBanner :data="videoData" />
    <Recruit></Recruit>
    <Th8Anniversary></Th8Anniversary>
    <Tabs></Tabs>
    <Swiper></Swiper>
  </div>
</template>

<style scoped lang="less">
.home {
  margin-top: -64px;
}
</style>

<script>
// @ is an alias to /src

import VideoBanner from '../../components/VideoBanner.vue';
import Tabs from "./Tabs";
import Swiper from "./Swiper";
// import Search from './Search'
import commonApi from "../../api/commonApi";
import Fingerprint2 from "fingerprintjs2";
import { setPreview } from "../../utils/common";
import Recruit from "./Recruit.vue";
import Th8Anniversary from "./Th8Anniversary.vue";
import { homeBannerData } from "@/utils/config";

export default {
  name: "Home",
  components: {
    Th8Anniversary,
    Recruit,
    VideoBanner,
    // Trainee,
    Tabs,
    // DailyTrainee,
    Swiper,
    // Search,
  },
  data() {
    return {
      videoData: homeBannerData
    };
  },
  created() {
    setPreview(this);
    try {
      this.saveReferrer();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    saveReferrer() {
      Fingerprint2.get(function (components) {
        const values = components.map(function (component, index) {
          if (index === 0) {
            //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
            return component.value.replace(/\bNetType\/\w+\b/, "");
          }
          return component.value;
        });
        const murmur = Fingerprint2.x64hash128(values.join(""), 31); // 生成最终id murmur
        let thirdWebsiteUrl = document.referrer;
        if (thirdWebsiteUrl) {
          let params = {
            webCode: murmur,
            thirdWebsiteUrl: thirdWebsiteUrl,
          };
          commonApi.saveReferrer(params).then(([result, response]) => { });
        }
      });
    },
  },
};
</script>
