import Vue from "vue"
import App from "./App.vue"
import "babel-polyfill"
import router from "./router"
// import axios from 'axios'
import "@/assets/css/index.less"
import "@/components/element"
import "@/components/vant"
import request from './utils/request'
Vue.use(request)
Vue.config.productionTip = false
// Vue.prototype._ = _

import plugins from './plugins/index'
Vue.use(plugins, { router })

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        if (to.path == "/mobile/home") {
            document.title = to.meta.title
        } else {
            document.title = to.meta.title + '-NIO蔚来'
        }
    }
    next()
})

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app")
