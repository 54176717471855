<template>
  <div class="wrap">
    <div class="app-footer main-w flex">
      <div class="flex" style="justify-content: center;align-items: center;">
        <img :src="text[type].logo" alt="NIO" class="icon" />
        <div class="desc">
          <h2>{{ text[type].title }}</h2>
          <p>{{ text[type].subtitle }}</p>
        </div>
      </div>
      <div class="qrcode">
        <img :title="text[type].qrTitle" :src="text[type].qr" :alt="text[type].qrTitle" draggable="false" />
      </div>
    </div>
    <div class="b-top main-w flex justy-between pt40">
      <div>
        <a href="/" target="_blank"><img src="~@/assets/img/logo.png" alt="" /></a>
        <!-- <p>蔚来校园招聘</p> -->
      </div>
      <div>
        <h3 class="title">相关网站</h3>
        <ul>
          <li><a href="https://www.nio.cn/" target="_blank">蔚来官网</a></li>
          <li>
            <a href="https://www.nio.cn/careers-list" target="_blank">蔚来社招官网</a>
          </li>
        </ul>
      </div>
      <div class="follow">
        <h3 class="title">关注我们</h3>
        <!--                <div class="flex mt10">-->
        <!--                    <div>-->
        <!--&lt;!&ndash;                        <i class="icon wx"></i>&ndash;&gt;-->
        <!--                        <span class="pic-wrap">-->
        <!--                            <el-image class="icon" :src="require('@/assets/img/qr-gzh.png')">-->
        <!--                                <div slot="placeholder" class="image-holder"></div>-->
        <!--                            </el-image>-->
        <!--                        </span>-->
        <!--                        <p>微信号</p>-->
        <!--                    </div>-->
        <!--                    <div class="ml50">-->
        <!--&lt;!&ndash;                        <i class="icon sph"></i>&ndash;&gt;-->
        <!--                        <el-image class="icon" :src="require('@/assets/img/qr-sph.png')">-->
        <!--                            <div slot="placeholder" class="image-holder"></div>-->
        <!--                        </el-image>-->
        <!--                        <p>视频号</p>-->
        <!--                    </div>-->
        <!--                </div>-->
        <ul style="display: flex">
          <li class="footer-icon">
            <img src="../assets/img/footer-wx.png" alt="" style="width: 32px; height: 32px" />
            <img class="hove-icon" src="../assets/img/hover-icon1.png" alt="" style="width: 100px; height: 100px" />
          </li>
          <li class="footer-icon">
            <img src="../assets/img/footer-nio.png" alt="" style="width: 32px; height: 32px" />
            <img class="hove-icon" src="../assets/img/hover-icon2.png" alt="" style="width: 100px; height: 100px" />
          </li>
          <li class="footer-icon">
            <img src="../assets/img/footer-douyin.png" alt="" style="width: 32px; height: 32px" />
            <img class="hove-icon" src="../assets/img/hover-icon3.png" alt="" style="width: 100px; height: 100px" />
          </li>
          <li class="footer-icon">
            <img src="../assets/img/footer-email.png" alt="" style="width: 32px; height: 32px" />
            <p class="hove-icon" style="top: -40px">campus@nio.com</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="copyright tc">
      <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer"><span>沪ICP备15044878号-5</span></a>

      <img src="~@/assets/img/copyright.png" alt="copyright" />

      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402003368" target="_blank"
        rel="noopener noreferrer"><span>沪公网安备31011402003368号</span></a>
    </div>
  </div>
</template>
<script lang="ts">
import nioLogo from '@/assets/img/app-footer/nio-app-logo.png';
import onvoLogo from '@/assets/img/app-footer/onvo-app-logo.jpeg';
import nioQr from '@/assets/img/app-footer/nio-app-qrcode.png';
import onvoQr from '@/assets/img/app-footer/onvo-app-qrcode.jpeg';
export default {
  data() {
    return {
      text: {
        nio: {
          logo: nioLogo,
          title: '扫码注册 NIO App，即可抽取惊喜盲盒',
          subtitle: '欢迎体验蔚来社区，聆听用户的故事，了解我们的最新资讯。',
          qr: nioQr,
          qrTitle: '即刻下载蔚来APP'
        },
        onvo: {
          logo: onvoLogo,
          title: '扫码关注乐道，了解最新资讯',
          qr: onvoQr,
          qrTitle: '即刻下载蔚来APP'
        }
      }
    };
  },
  computed: {
    type() {
      return this.$route.meta.type || 'nio'
    }
  }
};
</script>

<style scoped lang="less">
.wrap {

  // background: rgba(196, 196, 196, 0.19);
  .b-top {
    width: 80%;
    height: 210px;

    .title {
      font-size: 12px;
      color: #aaa;
      line-height: 19px;
    }

    ul {
      margin-top: 25px;

      li {
        padding-bottom: 14px;

        a {
          font-size: 12px;
          color: #040b29;
          line-height: 14px;

          &:hover {
            color: #41475e;
            text-decoration: underline;
          }
        }
      }
    }

    .icon {
      display: block;
      width: 60px;
      height: 60px;
      background-size: cover !important;

      &.wx {
        background: url("~@/assets/img/qr-gzh.png") center no-repeat;
      }

      &.sph {
        background: url("~@/assets/img/qr-sph.png") center no-repeat;
      }
    }

    &>div {
      &:nth-of-type(1) {
        a {
          display: inline-block;
          height: 44px;
          overflow: hidden;
        }

        img {
          width: 76px;
        }

        p {
          // margin-top: 8px;
          font-size: 18px;
          // font-weight: bold;
          // color: #040b29;
          line-height: 24px;
        }
      }
    }
  }

  .footer-icon {
    margin-right: 24px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hove-icon {
    position: absolute;
    top: -120px;
    display: none;
    //border-radius: 50%;
    //left: -2.9rem;
  }

  .footer-icon:hover .hove-icon {
    display: block;
  }

  .follow {
    p {
      margin-top: 8px;
      font-size: 12px;
      line-height: 19px;
      text-align: center;
      color: #41475e;
    }
  }

  .copyright {
    border-top: 1px solid #e6e7ec;
    height: 72px;
    line-height: 72px;
    font-size: 14px;

    img {
      width: 24px;
      height: 24px;
      margin: 0 12px;
      vertical-align: -5px;
    }

    span {
      color: #d0d0d0;
    }
  }
}

.app-footer {
  width: 90%;
  justify-content: space-evenly;
  align-items: center;
  padding: 64px 0;

  .icon {
    width: 80px;
    height: 80px;
    margin-right: 30px;
  }

  .desc {
    &>h2 {
      font-size: 20px;
      font-weight: 400;
    }

    &>p {
      font-size: 14px;
      font-weight: 300;
    }

    .link {
      font-size: 10px;
      margin: 10px 0;

      &>a {
        margin-right: 7px;
      }
    }
  }

  .qrcode {
    font-size: 10px;
    flex: none;
    text-align: center;
    margin-right: 30px;

    &>img {
      padding: 9px;
      border: 2px solid #000;
      width: 106px;
      height: 106px;
      margin: 0 auto 12px;
    }
  }
}
</style>
