<template>
  <div class="wrap" :class="{ 'no-bg': noBg && routerNeed }">
    <div class="header" :class="{ shadow: addShadow }">
      <!-- 导航logo -->
      <a class="logo" href="/">
        <h1 class="none">蔚来汽车-校园招聘</h1>
      </a>
      <!-- 导航内容 -->
      <ul class="nav-wrapper">
        <li v-for="(item, index) in navList" :key="index">
          <a v-if="item.isOuterLink" :href="item.path" target="_blank">{{
            item.name
          }}</a>
          <router-link
            v-else
            :to="item.path"
            :class="{
              active: item.path == currentPath,
              'noto-bold': item.path === '/',
            }"
          >
            {{ item.name }}
          </router-link>
        </li>
      </ul>
      <!-- <el-tooltip content="前往投递/查看应聘记录" visible-arrow placement="bottom" effect="light" popper-class="index-self-tooltip"> -->
      <div class="to-fs abs">
        <a
          class="center-v"
          href="https://www.nio.cn/careers-list"
          target="_blank"
          ><span>社招官网</span><i></i
        ></a>
      </div>
      <!-- </el-tooltip> -->
    </div>
  </div>
</template>

<script>
import { setPreview } from "../utils/common";

export default {
  name: "Header",
  data() {
    return {
      currentPath: "",
      menuName: "首页",
      navList: [
        {
          name: "主页",
          path: "/",
          children: [],
        },
        {
          name: "职位投递",
          // path: 'https://campus.nio.com',
          path: "https://nio.jobs.feishu.cn/campus",
          isOuterLink: true,
          children: [],
        },
        {
          name: "招聘动态",
          path: "/trends",
          children: [],
        },
        {
          name: "蔚来校园",
          path: "/nioSchool",
          children: [],
        },
        {
          name: "蔚来已来",
          path: "/learnNio",
          children: [],
        },
      ],
      noBg: true,
      addShadow: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.currentPath =
          this.$route.path.indexOf("/trends") === -1
            ? this.$route.path
            : "/trends";
        if (
          this.$route.name === "Home" ||
          this.$route.name === "Talent" ||
          this.$route.fullPath === "/trends?active=false&dataId=10&demo=1" ||
          this.$route.name == "Shine" || 
          this.$route.name == "starPlan"
        ) {
          this.noBg = true;
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // console.log('height:', document.body.scrollHeight)
      },
    },
  },
  computed: {
    routerNeed() {
      let route = this.$route;
      let needStatus =
        route.name === "Home" ||  route.name === "Home" || route.name === "equation"
        route.name === "Talent" ||
        route.fullPath === "/trends?active=false&dataId=10&demo=1" ||
        route.name === "Shine";
      return needStatus ? true : false;
    },
  },
  created() {
    let preview = setPreview(this);
    window.addEventListener("scroll", this.scrollHandler);
  },
  methods: {
    scrollHandler() {
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      let clientHeight = document.body.clientHeight;
      if (this.$route.name == "Home") {
        clientHeight = document.body.clientHeight;
      } else if (
        this.$route.name == "Talent" ||
        this.$route.fullPath === "/trends?active=false&dataId=10&demo=1"
      ) {
        clientHeight = 900;
      }
      this.noBg =
        scrollTop < clientHeight &&
        (this.$route.name !== "Home" ||
          this.$route.name !== "Talent" ||
          this.$route.fullPath === "/trends?active=false&dataId=10&demo=1");
      this.addShadow =
        (scrollTop >= clientHeight &&
          (this.$route.name !== "Home" ||
            this.$route.name !== "Talent" ||
            this.$route.fullPath ===
              "/trends?active=false&dataId=10&demo=1")) ||
        (scrollTop > 0 &&
          (this.$route.name !== "Home" ||
            this.$route.name !== "Talent" ||
            this.$route.fullPath === "/trends?active=false&dataId=10&demo=1"));
    },
  },
};
</script>
<style scoped lang="less">
.wrap {
  height: 64px;
}

.shadow {
  box-shadow: 0 5px 20px 1px rgba(174, 174, 174, 0.15);
  // box-shadow: 0px 5px 10px 1px rgba(174, 174, 174, 0.15);
}
.no-bg {
  .header {
    background: transparent !important;
  }

  .logo {
    background: url("~@/assets/img/logo-white.png") 100% no-repeat !important;
  }

  .nav-wrapper {
    a {
      color: #ffffff !important;
    }
  }

  .to-fs {
    a {
      color: #ffffff !important;

      i {
        background-image: url("~@/assets/img/to-fs-white.png") !important;
      }
    }
  }
}

.header {
  background: #fff; //rgba(255, 255, 255, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 111;
  height: 64px;

  .logo {
    position: absolute;
    left: 75px;
    top: 10px;
    z-index: 1111;
    width: 76px;
    height: 44px;
    background: url("~@/assets/img/logo.png") no-repeat;
    background-size: contain !important;
  }

  // .logo-white {
  //     background: url('~@/assets/img/logo-white.png') 100% no-repeat !important;
  // }

  .nav-wrapper {
    width: 666px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: #6f7385;

      &.active {
        color: #00b3be !important;
        font-weight: bold;
      }

      &:hover {
        color: #00b3be !important;
      }
    }
  }

  .to-fs {
    right: 75px;
    top: 20px;
    font-size: 14px;

    a {
      color: #6f7385;

      i {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-left: 8px;
        background: url("~@/assets/img/to-fs-normal.png") no-repeat center;
        background-size: contain;
      }
    }

    &:hover {
      a {
        color: #00b3be !important;

        i {
          background-image: url("~@/assets/img/to-fs-hover.png") !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1349px) {
  .header {
    .logo {
      left: 20px;
    }

    .to-fs {
      right: 20px;
    }
  }
}
</style>
