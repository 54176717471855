class ResponseResult {
  constructor (success, code, message, data) {
    this.success = success
    this.code = code
    this.message = message
    this.data = data
  }
}

export const result = (success, code, message, data) => {
  return new ResponseResult(success, code, message, data)
}

export const successResult = (message, data) => {
  return result(true, 200, message, data)
}

export const errorResult = (message, data) => {
  return result(false, 500, message, data)
}

export default {
  successResult,
  errorResult
}
