<template>
  <div class="first-screen">
    <video @playing.once="playing = true" ref="video" class="abs" :src="data.videoUrl" autoplay loop muted
      preload></video>
    <div class="content" :class="{ 'show-bg': !playing }">
      <div class="title">{{ data.title }}</div>
      <div class="subtitle" v-html="data.subtitle"></div>
      <a class="deliver-button" v-if="data.deliverUrl" :href="data.deliverUrl" target="_blank"
        :style="data.deliverButtonStyle">立即投递</a>
    </div>
    <div class="anchor-wrap" @click="scrollToAnchor">
      <slot>
        <div class="anchor"></div>
      </slot>
    </div>
    <div ref="anchor" class="scroll-anchor"></div>
  </div>
</template>

<script>
export default {
  name: 'VideoBanner',
  props: {
    data: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
  data() {
    return {
      playing: false,
    };
  },
  methods: {
    scrollToAnchor() {
      // this.$refs['anchor'].scrollIntoView({ behavior: 'smooth' })
      this.$smoothDown(this.$refs["anchor"], 100);
    },
  },
};
</script>

<style scoped lang="less">
.scroll-anchor {
  position: absolute;
  bottom: -1px;
}

.first-screen {
  // width: 100vw;
  height: 100vh;
  position: relative;
  margin-top: -64px;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    left: 0;
    top: 0;
  }

  .show-bg {
    background-color: rgba(156, 198, 207, 0.75) !important;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 56px;
      color: #fff;
      line-height: 1.5;
      letter-spacing: 7px;
      font-weight: 400;
    }

    .subtitle {
      margin-top: 8px;
      font-size: 20px;
      font-weight: 400;
      color: #fff;
      line-height: 1.5;
      letter-spacing: 2px;
    }

    .deliver-button {
      margin-top: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 222px;
      height: 48px;
      border-radius: 2px;
      background-color: transparent;
      transition: background-color 0.3s;
      line-height: 48px;
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;

      &:hover {
        border: 1px solid #00b3be;
        background-color: #00b3be;
        color: #fff;
      }
    }
  }

  @keyframes jump {
    0% {
      bottom: 15%;
    }

    50% {
      bottom: calc(15% + 7px);
    }

    100% {
      bottom: 15%;
    }
  }

  .anchor-wrap {
    position: absolute;
    left: 50%;
    bottom: 15%;
    z-index: 2;
    transform: translate(-50%, 0);
    animation: jump 1.5s linear infinite;
  }

  .anchor {
    display: inline-block;
    width: 24px;
    height: 29px;
    cursor: pointer;
    background: url("~@/assets/img/slide-down.png") center no-repeat;
    background-size: contain;
  }
}
</style>
