//判断是否是数组
import {removeSessionCache, setSessionCache} from "./cache";

export const isArray = (value) => {
    if (Object.prototype.toString.call(value) == '[object Array]') {
        return true
    }
    return false
}

//判断是否是对象
export const isObject = (value) => {
    if (Object.prototype.toString.call(value) == '[object Object]') {
        return true
    }
    return false
}

//生成UUID
export const UUID = () => {
    let s = []
    let hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'
    let uuid = s.join('')
    return uuid
}

//下载文件
export const downloadFile = (result, response, target = '_self') => {
    let blob = new Blob([response.data])
    let downloadElement = document.createElement('a')
    let href = window.URL.createObjectURL(blob) //创建下载的链接
    downloadElement.href = href
    downloadElement.target = target
    downloadElement.download = response.headers['file-name'] //下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() //点击下载
    document.body.removeChild(downloadElement) //下载完成移除元素
    window.URL.revokeObjectURL(href) //释放掉blob对象
}

//设置
export const setPreview = (that) => {
    try {
        let _view = that.$route.query._view;
        if (_view) {
            console.log("开启预览模式")
            setSessionCache("_view", _view)
        }
        return _view
    } catch (e) {
        console.error(e)
    }
    return null;
}

export default {
    isArray,
    isObject,
    UUID,
    downloadFile
}
