import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=30d39d63&scoped=true"
import script from "./Tabs.vue?vue&type=script&lang=js"
export * from "./Tabs.vue?vue&type=script&lang=js"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=30d39d63&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d39d63",
  null
  
)

export default component.exports