<template>
  <div class="recruit">
    <div class="recruit-title">校园招聘项目</div>
    <div class="recruit-container">
      <div class="recruit-wrap">
        <div class="recruit-list" v-for="(item, index) in dataSource" :key="index">
          <div class="list-title">{{ item.title }}</div>
          <div class="content">
            <div class="desc">
              <p>{{ item.desc }}</p>
            </div>
            <div v-if="item.descUrl" class="detail" @click="jump(item.descUrl)">
              了解详情
              <img src="../../assets/img/detail-icon.png" alt="" style="width: 18px; margin-top: -4px" />
            </div>
          </div>
          <div class="recruit-button" @click="jump(item.deliverUrl)">
            立即投递
          </div>
        </div>
      </div>
      <img class="recruit-image" :src="homeRecruitImage" alt="" />
    </div>
  </div>
</template>

<script>
import { homeRecruitModules, homeRecruitImage } from "@/utils/config";
export default {
  data() {
    return {
      dataSource: homeRecruitModules,
      homeRecruitImage
    };
  },
  methods: {
    jump(url) {
      if (url.startsWith("http")) {
        window.open(url);
      } else {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style scoped lang="less">
.recruit {
  width: 100%;
  padding-top: 90px;
  padding-bottom: 62px;
  background: linear-gradient(to bottom, #c5b4a3 20%, #a8c2c2 80%) no-repeat center;
  min-height: 100vh;
  background-size: cover;
}

.recruit-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #000000;
  margin-bottom: 31px;
}

.recruit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recruit-image {
  width: 0;
  height: 100%;
  flex: 4;
  object-fit: contain;
  object-position: center;
}

.recruit-wrap {
  width: 100%;
  flex: 3;
  padding-left: 80px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.recruit-list {
  width: calc(50% - 20px);
  height: 276px;
  margin-right: 20px;
  margin-top: 20px;
  border: none;
  // box-shadow: 0 0 15px 0 rgba(163, 194, 194, 0.1);
  background: #ffffff;
  padding: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);

  &:hover {
    box-shadow: 0 0 15px 0 rgba(163, 194, 194, 0.1);
  }
}

.content {
  margin-top: 20px;
  flex: 1;
}

.list-title {
  font-size: 19px;
  white-space: pre-wrap;
}

.desc {
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 22px;
  white-space: pre-wrap;
}

.detail {
  /*cursor: pointer;*/
  font-size: 14px;
  color: #0fb0ba;
}

.time {
  font-size: 14px;
  /*margin-bottom: 12px;*/
}

.recruit-button {
  /*width: 242px;*/
  height: 32px;
  line-height: 32px;
  border: 1px solid rgba(15, 176, 186, 0.5);
  color: #0fb0ba;
  font-size: 14px;
  text-align: center;
  /*cursor: pointer;*/
}

.recruit-button:hover {
  color: #ffffff;
  border: 1px solid #00b3be;
  background: #00b3be;
  transition: background 0.3s;
}
</style>
