export class ExpiresCacheValue {
  constructor(value, expires) {
    this.value = value;
    this.hoseleLocalCacheExpires = expires;
    this.startTime = new Date();
  }
}

/**
 * 设置本地永久缓存
 * @param name
 * @param value
 */
export const setLocalCache = (name, value, expires) => {
  if (expires && typeof expires === 'number' && !isNaN(expires)) {
    let expiresCacheValue = new ExpiresCacheValue(value, expires);
    window.localStorage.setItem(name, JSON.stringify(expiresCacheValue))
  } else {
    if (Object.prototype.toString.call(value) == '[object Object]') {
      window.localStorage.setItem(name, value)
    }
    if (Object.prototype.toString.call(value) == '[object Array]') {
      window.localStorage.setItem(name, value)
    } else {
      window.localStorage.setItem(name, value)
    }
  }
}


/**
 * 设置本地会话缓存，页面关闭就会被清除
 * @param name
 * @param value
 */
export const setSessionCache = (name, value, expires) => {
  if (expires && typeof expires === 'number' && !isNaN(expires)) {
    let expiresCacheValue = new ExpiresCacheValue(value, expires);
    window.sessionStorage.setItem(name, JSON.stringify(expiresCacheValue))
  } else {
    if (Object.prototype.toString.call(value) == '[object Object]') {
      window.sessionStorage.setItem(name, value)
    }
    if (Object.prototype.toString.call(value) == '[object Array]') {
      window.sessionStorage.setItem(name, value)
    } else {
      window.sessionStorage.setItem(name, value)
    }
  }
}


/**
 * 获取缓存
 * @param name
 */
export const getLocalCache = (name) => {
  if (!name) return false;
  let item = window.localStorage.getItem(name);
  if (!item) return item;
  try {
    item = JSON.parse(item);
  } catch (error) {
    return item;
  }
  if (!item.hoseleLocalCacheExpires) return item; //没有设置过期时间，直接返回
  let startTime = new Date(item.startTime);
  startTime.setUTCSeconds(startTime.getUTCSeconds() + item.hoseleLocalCacheExpires);
  if (startTime.getTime() > new Date().getTime()) {
    return item.value;
  }
  window.localStorage.removeItem(name);
  return null;
}

/**
 * 获取会话内的缓存
 * @param name
 */
export const getSessionCache = (name) => {
  if (!name) return false;
  let item = window.sessionStorage.getItem(name);
  if (!item) return item;
  try {
    item = JSON.parse(item);
  } catch (error) {
    return item;
  }
  if (!item.hoseleLocalCacheExpires) return item; //没有设置过期时间，直接返回
  let startTime = new Date(item.startTime);
  startTime.setUTCSeconds(startTime.getUTCSeconds() + item.hoseleLocalCacheExpires);
  if (startTime.getTime() > new Date().getTime()) {
    return item.value;
  }
  window.sessionStorage.removeItem(name);
  return null;
}

/**
 * 移除指定缓存
 * @param name
 */
export const removeLocalCache = (name) => {
  window.localStorage.removeItem(name);
}


/**
 * 移除会话内的指定缓存
 * @param name
 */
export const removeSessionCache = (name) => {
  window.sessionStorage.removeItem(name);
}


export const clearLocalCache = () => {
  window.localStorage.clear();
}


export const clearSessionCache = () => {
  window.sessionStorage.clear();
}
