import {Message} from "element-ui";
import Vue from 'vue'

export function errorClose(message, duration = 3000) {
  Message({
    dangerouslyUseHTMLString: true,
    showClose: true,
    duration: duration,
    message: message,
    type: 'error'
  });

}

export function successClose(message, duration = 1500) {
  Message({
    dangerouslyUseHTMLString: true,
    showClose: true,
    duration: duration,
    message: message,
    type: 'success'
  });
}


export function confirmMessage(message, title = '提示') {
  return new Promise((resolve, reject) => {
    Vue.prototype.$confirm(message, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      resolve();
    }).catch(() => {
      reject();
    });
  });
}

export default {
  errorClose,
  successClose,
  confirmMessage
}
