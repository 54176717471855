import axios from "axios";
import mergeConfig from "axios/lib/core/mergeConfig";
import qs from "qs";

/**
 * json 对象转FormData
 * @param val
 * @returns {FormData}
 */
let toFormData = (obj) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    if (obj[key] instanceof Array) {
      obj[key].forEach((item) => {
        formData.append(key, item);
      });
      return;
    }
    formData.append(key, obj[key]);
  });
  return formData;
};

function promiseLimit(funcArray, limit = 5) {
  let i = 0;
  const result = [];
  const executing = [];
  const queue = function () {
    if (i === funcArray.length) return Promise.all(executing);
    const p = funcArray[i++]();
    result.push(p);
    const e = p.then(() => executing.splice(executing.indexOf(e), 1));
    executing.push(e);
    if (executing.length >= limit) {
      return Promise.race(executing).then(
        () => queue(),
        (e) => Promise.reject(e)
      );
    }
    return Promise.resolve().then(() => queue());
  };
  return queue().then(() => Promise.all(result));
}

/**
 * 请求头 配置
 * @returns {{baseURL: *, loadingDom: string, timeout: number}}
 */
const requestConfig = (hoseleConfig) => {
  fullHeaders(hoseleConfig); //填充头部
  let config = {
    //baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 300000,
    loadingDom: "请求中...",
    baseURL: process.env.VUE_APP_BASE_URL,
  };
  Object.assign(config, hoseleConfig);
  return config;
};

/**
 * 填充头部
 * @param hoseleConfig
 * @returns {*}
 */
const fullHeaders = (hoseleConfig) => {
  if (!hoseleConfig.headers) hoseleConfig.headers = {};
  return hoseleConfig;
};

const putConfig = (hoseleConfig, config) => {
  if (!hoseleConfig) {
    hoseleConfig = {};
  }
  if (config.headers) {
    if (!hoseleConfig.headers) hoseleConfig.headers = {};
    Object.assign(hoseleConfig.headers, config.headers);
  }
};

const installRequest = (hoseleConfig) => {
  let request = {
    axiosInstance: null,
    create: function () {
      this.axiosInstance = axios.create(requestConfig(hoseleConfig));
      return this;
    },
    installConfig: function (hoseleConfig) {
      let defaults = mergeConfig(
        this.axiosInstance.defaults,
        requestConfig(hoseleConfig)
      );
      return defaults;
    },
    get: function (url, params = {}, hoseleConfig = {}) {
      try {
        let promise = new Promise((resolve, reject) => {
          hoseleConfig["params"] = params;
          this.installConfig(hoseleConfig)
            .get(url, hoseleConfig)
            .then(function (response) {
              //正确回调
              resolve([response.data, response]);
            })
            .catch(function (response) {
              //请求已发出，但服务器响应的状态码不在 2xx 范围内
              reject(response);
            });
        });
        return promise;
      } catch (e) {
        let promise = new Promise((resolve, reject) => {
          reject({
            success: false,
            message: e.message,
          });
        });
        return promise;
      }
    },
    post: function (url, params, hoseleConfig) {
      try {
        let promise = new Promise((resolve, reject) => {
          let installConfig = this.installConfig(hoseleConfig);
          this.axiosInstance
            .post(url, params, installConfig)
            .then((response) => {
              //正确回调
              if (response.stack) {
                resolve([
                  { success: false, data: null, msg: response.message },
                  response,
                ]);
              } else {
                resolve([response.data, response]);
              }
            })
            .catch(function (response) {
              //请求已发出，但服务器响应的状态码不在 2xx 范围内
              reject(response);
            });
        });
        return promise;
      } catch (e) {
        let promise = new Promise((resolve, reject) => {
          reject({
            success: false,
            message: e.message,
          });
        });
        return promise;
      }
    },
    postXForm: function (url, params = {}, hoseleConfig = {}) {
      putConfig(hoseleConfig, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      });
      return this.post(
        url,
        qs.stringify(params, { arrayFormat: "indices" }),
        hoseleConfig
      );
    },
    postObjFormData: function (url, params = {}, hoseleConfig = {}) {
      return this.postFormData(url, toFormData(params), hoseleConfig);
    },
    postFormData: function (url, params = new FormData(), hoseleConfig = {}) {
      putConfig(hoseleConfig, {
        headers: { "Content-Type": "form-data;charset=UTF-8" },
      });
      return this.post(url, params, hoseleConfig);
    },
    postJson: function (url, params = {}, hoseleConfig = {}) {
      putConfig(hoseleConfig, {
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
      return this.post(url, JSON.stringify(params), hoseleConfig);
    },
    postFile: function (url, params = {}, hoseleConfig = {}) {
      if (!hoseleConfig["timeout"])
        hoseleConfig["timeout"] = 3 * 60 * 60 * 1000;
      putConfig(hoseleConfig, {
        headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      });
      return this.post(url, toFormData(params), hoseleConfig);
    },
    postBigFile: function (url, params = {}, hoseleConfig = {}) {
      let promise = new Promise((resolve, reject) => {
        if (!hoseleConfig["timeout"]) {
          hoseleConfig["timeout"] = 3 * 60 * 60 * 1000;
        }
        putConfig(hoseleConfig, {
          headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
        });
        let file = params.file;
        const chunk = params.chunk ? params.chunk : 100; //默认每个分片的大小是100M
        const chunkSize = chunk * 1024 * 1024; //每片的大写
        const chunks = Math.ceil(file.size / chunkSize); //切片的总次数
        const sumSize = file.size; //文件总大小
        const results = [];
        const promises = [];
        for (let i = 0; i < chunks; i++) {
          const startSize = i * chunkSize;
          let endSize = Math.min(sumSize, startSize + chunkSize);
          const newParams = { ...params };
          const newConfig = { ...hoseleConfig };
          newParams.file = file.slice(startSize, endSize);
          newParams.chunk = i;
          newParams.fileName = file.name;
          newParams.totalChunk = chunks;
          if (hoseleConfig.onUploadProgress) {
            newConfig.onUploadProgress = (progressEvent) => {
              let event = { ...progressEvent };
              event.chunk = i;
              event.chunks = chunks;
              event.loaded = progressEvent.loaded;
              event.total = newParams.file.size;
              hoseleConfig.onUploadProgress(event);
            };
          }
          promises.push(function () {
            let httpPromise = post(url, toFormData(newParams), newConfig);
            return httpPromise;
          });
        }
        promiseLimit(promises).then((data) => {
          let finish = {};
          data.forEach((value, index) => {
            if (!value[0].success) {
              results.push(value.data.errorChunk);
            }
            if (value[0].success && value[0].data.state == 2) {
              finish = value[0];
            }
          });
          resolve([results, finish]);
        });
      });
      return promise;
    },
  };
  request.create(hoseleConfig);
  return request;
};

export default {
  installRequest,
};
