import { render, staticRenderFns } from "./VideoBanner.vue?vue&type=template&id=379f5c15&scoped=true"
import script from "./VideoBanner.vue?vue&type=script&lang=js"
export * from "./VideoBanner.vue?vue&type=script&lang=js"
import style0 from "./VideoBanner.vue?vue&type=style&index=0&id=379f5c15&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379f5c15",
  null
  
)

export default component.exports