import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function push(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
import Home from "../views/home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "校园招聘",
    },
  },
  {
    path: "/trends",
    name: "Trends",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/trends/Trends.vue"),
    meta: {
      title: "招聘动态",
    },
  },
  {
    path: "/onvo",
    name: "OnvoRegion",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/OnvoRegion/OnvoRegion.vue"),
    meta: {
      title: "乐道校园招聘",
      type: "onvo",
    },
  },
  {
    path: "/nioSchool",
    name: "nioSchool",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/nioSchool/index.vue"),
    meta: {
      title: "蔚来校园",
    },
  },
  {
    path: "/learnNio",
    name: "learnNio",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/learnNio/index.vue"),
    meta: {
      title: "蔚来已来",
    },
  },
  {
    path: "/equation",
    name: "equation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/equation/index.vue"),
    meta: {
      title: "大学生方程式",
    },
  },
  {
    path: "/learnNioMore",
    name: "learnNioMore",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/learnNioMore/index.vue"),
    meta: {
      title: "了解更多",
    },
  },
  {
    path: "/mobile",
    component: () => import("@views/mobile/mobile.vue"),
    meta: {
      title: "区域公司校招",
    },
    children: [
      {
        path: "",
        name: "MobileHome",
        redirect: "home",
      },
      {
        path: "onvo",
        name: "MobileOnvo",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/mobile/Onvo.vue"),
        meta: {
          title: "乐道校园招聘",
        },
      },
      {
        path: "home",
        name: "home",
        component: () => import("../views/mobile/home.vue"),
        meta: {
          title: "NIO蔚来校招",
        },
      },
      {
        path: "mobile",
        name: "MobileRegion",
        component: () => import("@views/mobile/Region.vue"),
        meta: {
          title: "区域公司校招",
        },
      },
      // {
      //   path: "talent",
      //   name: "talent",
      //   component: () => import("../views/mobile/talent.vue"),
      //   meta: {
      //     title: "方程式提前批校招",
      //   },
      // },
      // {
      //   path: "publicity",
      //   name: "talent",
      //   component: () => import("../views/mobile/talent.vue"),
      //   meta: {
      //     title: "校园招聘宣讲",
      //   },
      // },
      // {
      //   path: "recruit",
      //   name: "recruit",
      //   component: () => import("../views/mobile/talent.vue"),
      //   meta: {
      //     title: "秋季校园招聘研发提前批",
      //   },
      // },
      // {
      //   path: "nio_u", //0708新增
      //   name: "nio_u",
      //   component: () => import("../views/mobile/talent.vue"),
      //   meta: {
      //     title: "NIO YOU提前批重磅来袭",
      //   },
      // },
      {
        path: "trends",
        name: "MobileTrends",
        component: () => import("../views/mobile/trends.vue"),
        meta: {
          title: "招聘动态",
        },
      },
      // {
      //   path: "autumnRecruit", //818新增
      //   name: "autumnRecruit",
      //   component: () => import("../views/mobile/talent.vue"),
      //   meta: {
      //     title: "2023秋招正式",
      //   },
      // },
      {
        path: "shine", //818新增
        name: "shine",
        component: () => import("../views/mobile/talent.vue"),
        meta: {
          title: "shine校园招募计划",
        },
      },
      // {
      //   path: "/mobile/traineePlan",//20230721新增
      //   name: "traineePlan",
      //   component: () => import("../views/mobile/traineePlan.vue"),
      // },
      {
        path: "traineePlan", //20230721新增
        name: "traineePlan",
        component: () => import("../views/mobile/basicDetailPage.vue"),
        meta: {
          imageUrl:
            "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/手机端-校园招聘详情.jpg?imageView2/interlace/1",
        },
      },
    ],
  },
  // {
  //   path: "/status",
  //   name: "Status",
  //   component: () => import("../views/health/Status.vue"),
  //   meta: {
  //     title: "健康检查",
  //   },
  // },
  {
    path: "/talent",
    name: "Talent",
    component: () => import("../views/talent/Talent.vue"),
    meta: {
      title: "大学生方程式",
    },
  },
  {
    path: "/trends/recruit",
    name: "recruit",
    component: () => import("../views/trends/equation.vue"),
    meta: {
      title: "秋季校园招聘研发提前批",
    },
  },
  {
    path: "/trends/equation",
    name: "trendsequation",
    component: () => import("../views/trends/equation.vue"),
    meta: {
      title: "大学生方程式公告",
    },
  },
  {
    path: "/trends/webPublicity",
    name: "webPublicity",
    component: () => import("../views/trends/equation.vue"),
    meta: {
      title: "蔚来校园宣讲",
    },
  },
  // {
  //   path: "/trends/nio_u", //0708新增
  //   name: "Trends",
  //   component: () => import("../views/trends/equation.vue"),
  //   meta: {
  //     title: "NIO YOU提前批重磅来袭",
  //   },
  // },
  {
    path: "/trends/shine", //0726新增
    name: "Shine",
    component: () => import("../views/trends/shine.vue"),
    meta: {
      title: "Shine校园招募计划",
    },
  },
  // {
  //   path: "/trends/autumnRecruit", //0816新增
  //   name: "autumnRecruit",
  //   component: () => import("../views/trends/equation.vue"),
  //   meta: {
  //     title: "2023届，秋招",
  //   },
  // },
  {
    path: "/student",
    name: "Student",
    component: () => import("../views/student/Student.vue"),
    meta: {
      title: "Hello NIOer",
    },
  },
  {
    path: "/trends/starPlan", //20230710新增
    name: "StarPlan",
    component: () => import("../views/trends/StarPlan.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
});

export default router;
