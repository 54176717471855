<template>
  <div class="first-screen pr">
    <video
      ref="YL"
      @playing.once="playing = true"
      class="abs"
      src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/首页-周年视频预览.mp4"
      preload
      autoplay
      loop
      muted
    ></video>

    <div class="content-wrap pr tc show-bg">
      <div v-if="!equation">
        <div class="titles">
          <div class="slogan">{{ bannerData.videoWordOne }}</div>
          <div class="subtitle">
            <span>{{ bannerData.videoWordTwo }}</span>
          </div>
        </div>
        <div class="play" @click="playVideo">
          观看视频
          <img
            src="../../assets/img/play-icon.png"
            alt=""
            style="width: 26.5px; margin-top: -3px"
          />
        </div>
      </div>
    </div>

    <!-- 小视频弹框  -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="bannerData.videoWordOne"
      :visible.sync="dialogTableVisible"
      @close="closeDialog"
    >
      <video
        ref="videos"
        @playing.once="playing = true"
        @ended="onPlayerEnded($event)"
        src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/首页-9周年视频.mp4"
        controls
        autoplay
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import { getHomeVideo } from "@/api/commonApi";

export default {
  name: "Th8Anniversary",
  props: {
    equation: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data() {
    return {
      bannerData: {
        id: "1",
        videoName: "首页视频",
        videoUrl:
          "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_home_video1.mp4",
        videoWordOne: "走进蔚来九周年",
        videoWordTwo:
          "蔚来是一家全球化的智能电动汽车公司，于2014年11月25日正式成立，覆盖全球超300个城市的用户。英文品牌NIO取意A NEW DAY（新的一天）。",
        createTime: "2022-05-10 14:13:02",
        updateTime: "2022-05-10 16:46:28",
        deleted: 0,
        linkUrl:
          "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7257447706321455397&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=&spread=7Q6WWWR",
        empty: false,
      },
      playing: false,
      isPlay: false,
      dialogTableVisible: false,
      videoUrl: null,
      title: null,
      disabledPlay: true,
    };
  },
  methods: {
    closeDialog() {
      // 关闭弹框
      this.dialogTableVisible = false;
      this.videoUrl = null;
      // this.$refs.videos.controls = false
      this.$refs.videos.pause();
      this.$refs.YL.play();
    },
    onPlayerEnded() {
      this.disabledPlay = true;
      this.$refs.videos.controls = true;
      this.$refs.videos.pause();
      this.$refs.videos.load();
    },
    playVideo() {
      // this.isPlay = true;
      // this.$refs.video.play();
      this.dialogTableVisible = true;
      this.$refs.YL.pause();
    },
    scrollToAnchor() {
      // this.$refs['anchor'].scrollIntoView({ behavior: 'smooth' })
      this.$smoothDown(this.$refs["anchor"], 100);
    },
  },
  mounted() {
    // this.$refs.video.addEventListener('ended', () => {
    //   this.isPlay = false;
    // })
  },
};
</script>

<style scoped lang="less">
.play {
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  bottom: 44px;
  left: 46.5%;
  cursor: pointer;
}

.scroll-anchor {
  bottom: -1px;
}

@keyframes jump {
  0% {
    padding-top: 2px;
  }
  50% {
    padding-top: 9px;
  }
  100% {
    padding-top: 2px;
  }
}

.first-screen {
  // width: 100vw;
  height: 100vh;
  //height: 900px;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    left: 0;
    top: 0;
  }

  .show-bg {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .content-wrap {
    padding-top: 200px;
    width: 100%;
    height: 100%;
    z-index: 1;
    // background: rgba(19, 60, 154, 0.2);
    .titles {
      height: 125px;
    }

    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;

    .slogan {
      font-size: 46px;
      // font-family: arial !important;
      // font-weight: bold;
      color: #fff;
      line-height: 72.96px;
      letter-spacing: 7px;
      font-weight: 100;
    }

    .subtitle {
      margin-top: 8px;
      font-size: 16px;
      color: #ffffff;
      line-height: 28.8px;
      letter-spacing: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        width: 1200px;
      }
    }

    .apply-btns {
      display: flex;
      justify-content: center;

      margin: 72px auto 0;
      width: 476px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 222px;
        height: 48px;
        // line-height: 48px;
        // text-align: center;
        border-radius: 2px;
        background-color: transparent;
        transition: background-color 0.3s;

        &:first-child {
          background-color: #fff;
          color: #00b3be;

          &:hover {
            background-color: #00b3be;
            color: #fff;
          }
        }

        &:last-child {
          line-height: 46px;
          background-color: rgba(255, 255, 255, 0.1);
          color: #ffffff;

          &:hover {
            border: 1px solid #00b3be;
            background-color: #00b3be;
            color: #fff;
          }
        }
      }
    }
  }

  .tap-wrap {
    bottom: 15%;
    z-index: 2;

    .anchor-wrap {
      display: inline-block;
      width: 30px;
      height: 30px;
      // border: 1px solid #00b3be;
      // border-radius: 50%;
      animation: jump 1.5s linear infinite;
    }

    a {
      display: inline-block;
      width: 24px;
      height: 29px;
      background: url("~@/assets/img/slide-down.png") center no-repeat;
      background-size: contain;
    }
  }
}
</style>
