<template>
  <div class="main-w mainw">
    <div class="recruit-title">探索你感兴趣的岗位</div>
    <search></search>
    <el-carousel
      ref="nop"
      :interval="4000"
      indicator-position="none"
      :autoplay="false"
      arrow="never"
      height="500px"
    >
      <el-carousel-item
        class="el-car-item"
        v-for="(list, index) in dataList"
        :key="index"
      >
        <div
          class="img-list"
          v-for="(imgList, index2) in list"
          :key="index2"
          @click="geturl(imgList)"
        >
          <img class="top-img" :src="imgList.img" :alt="imgList.title" />
          <p class="top-text">{{ imgList.text }}</p>
          <div class="mask"></div>
          <div class="desc" v-html="imgList.desc"></div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="arrow">
      <div class="left_yuan" v-show="curIndex === 0">
        <img src="../../assets/img/left-arrow.png" alt="" style="width: 54px" />
      </div>
      <div class="left_yuan" @click="cardPunt()" v-show="curIndex !== 0">
        <img
          src="../../assets/img/right-arrow.png"
          alt=""
          style="width: 54px; transform: rotate(180deg)"
        />
      </div>
      <div class="right_yuan" v-show="curIndex === 1">
        <img
          src="../../assets/img/left-arrow.png"
          alt=""
          style="width: 54px; transform: rotate(180deg)"
        />
      </div>
      <div class="right_yuan" @click="cardNext()" v-show="curIndex !== 1">
        <img
          src="../../assets/img/right-arrow.png"
          alt=""
          style="width: 54px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { homePostData } from "@/utils/config";
import Search from "./Search.vue";
export default {
  components: {
    Search,
  },
  data() {
    return {
      dataList: homePostData,
      curIndex: 0,
    };
  },
  mounted() {
    this.Lunbo();
  },
  methods: {
    cardPunt() {
      this.curIndex = 0;
      this.$refs.nop.prev();
    },
    cardNext() {
      this.curIndex = 1;
      this.$refs.nop.next();
    },
    Lunbo() {
      // 拆分5+5
      let newDataList = [];
      let current = 0;
      if (this.dataList && this.dataList.length > 0) {
        for (let i = 0; i <= this.dataList.length - 1; i++) {
          if (i % 5 !== 0 || i === 0) {
            if (!newDataList[current]) {
              newDataList[current] = [this.dataList[i]];
            } else {
              newDataList[current].push(this.dataList[i]);
            }
          } else {
            current++;
            newDataList[current] = [this.dataList[i - 1]];
            newDataList[current].push(this.dataList[i]);
          }
        }
      }
      this.dataList = [...newDataList];
    },
    handleClick() {},
    geturl(item) {
      if (item.linkUrl) {
        window.open(item.linkUrl, "_blank");
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import (reference) "~@/assets/css/func.less";
.recruit-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #000000;
  //margin-bottom: 31px;
}
.el-car-item {
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  //.top-img {
  //  width: 284px;
  //  height: 184px;
  //  margin-right: 20px;
  //  cursor: pointer;
  //}
}
.img-list {
  width: 224px;
  height: 445px;
  //margin-right: 40px;
  //margin-left: 20px;
  cursor: pointer;
  box-shadow: 0 0 15px 0 rgba(24, 26, 32, 0.08);
  background: #ffffff;
  position: relative;
  //flex: 1;
}
.img-list img {
  width: 100%;
  height: 387px;
}

.img-list .top-text {
  padding-left: 16px;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  margin-top: 13px;
}
.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  //margin-top: 40px;
  margin-bottom: 109.5px;

  .left_yuan {
    margin-right: 74.5px;
  }
}
.mask {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 387px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  animation: change 0.5s;
  display: none;
}
.desc {
  font-size: 13px;
  color: #ffffff;
  line-height: 20.62px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 20px 16px;
  display: none;
}
.img-list:hover .mask {
  display: block;
}
.img-list:hover .desc {
  display: block;
}
@keyframes changeData {
  0% {
    top: 0px;
  }
  100% {
    top: 319px;
  }
}
//.linkNull:hover{
//    .shade{
//        display: block;
//        position: absolute;
//        z-index: 9;
//        top: 0px;
//        left: 0px;
//        width: 100%;
//        height: 319px;
//        border-radius: 4px 4px 0px 0px;
//        overflow: hidden;
//        background-color: rgba(0,0,0,0.4);
//        animation: change 0.5s;
//    }
//}
.miniCard.miniCard_box0 .leftData .leftCardst {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 240px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  animation: change 0.5s;
}
.mainw {
  padding-top: 80px;
  background: #ffffff;
  width: 100%;
  min-width: 1280px;
}
/deep/ .el-card {
  border: none;
  box-shadow: 0px 10px 20px 1px rgba(174, 174, 174, 0.1);

  .el-card__body {
    padding: 0;
  }

  &:hover {
    box-shadow: 0px 4px 30px 4px rgba(174, 174, 174, 0.25),
      0px 10px 20px rgba(174, 174, 174, 0.1);
  }
}
.linkNull {
  /deep/ .el-card {
    border: none;
    box-shadow: 0px 10px 20px 1px rgba(174, 174, 174, 0.1);
    cursor: default;

    .el-card__body {
      padding: 0;
    }
  }
}
.main-tabs {
  // width:384px;
  height: 48px;
  margin: 0 auto;
  background: rgba(147, 151, 153, 0.1);
  border-radius: 2px;
  overflow: hidden;

  /deep/ .el-tabs {
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .el-tabs__content {
      display: none;
    }
    .el-tabs__nav {
      .el-tabs__active-bar {
        height: 100%;
        background-color: #00b3be;
        border: 0;
        border-radius: 2px;
      }
      .el-tabs__item {
        height: 48px;
        width: 170px;
        padding: 0;
        line-height: 48px;
        text-align: center;
        z-index: 1;
        font-size: 16px;
        color: #939799;
        &.is-active {
          color: #fff !important;
        }
        &:hover {
          color: #41475e;
        }
      }
    }
  }
}

.tabs {
  padding: 0 188px;
  border-bottom: 1px solid #ced0d8;
  /deep/ .el-tabs {
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .el-tabs__content {
      display: none;
    }
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__nav {
      .el-tabs__active-bar {
        height: 2px;
        // background-color: #00b3be;
        border: 0;
        padding: 0 16px;
        // box-sizing: content-box;
        background-color: #00b3be;
      }
      .el-tabs__item {
        height: 68px;
        // width: 170px;
        // padding: 0 16px;
        box-sizing: border-box;
        line-height: 68px;
        text-align: center;
        z-index: 1;
        font-size: 16px;
        color: #939799;
        &.is-active {
          color: #040b29;
        }

        &:hover {
          // text-decoration: underline;
          color: #41475e;
          // border-bottom: 1px solid #00b3be;
        }
      }
    }
  }
}

.cards-wrap {
  padding: 30px 82px 59px;
  .row {
    padding-bottom: 48px;
    display: flex;
    justify-content: space-between;

    a {
      display: inline-block;
      position: relative;
    }
    .linkNull:hover .shade {
      display: flex;
      position: absolute;
      z-index: 9;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 319px;
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.4);
      animation: change 0.5s;
    }
    .shade {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.36);
      border-radius: 4px;
      overflow: hidden;
      cursor: default;
      display: none;
      //display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      p {
        line-height: 28px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
      }
    }
    .post-card {
      width: 316px;
      height: 319px;
      position: relative;
      // .img {
      //     display: block;
      //     width: 100%;
      //     height: 178px;
      //     background-size: cover;
      //     background-repeat: no-repeat;
      //     background-position: center;
      // }
      // img {
      //     display: block;
      //     width: 100%;
      //     height: 178px;
      //     object-fit: cover;
      // }
      .el-image {
        height: 178px;
        width: 100%;
        /deep/ img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .image-holder {
          height: 100%;
          background: rgba(156, 198, 207, 0.39)
            url("~@/assets/img/image-holder.png") center no-repeat;
          background-size: 82px 82px;
        }
      }
      .summary {
        padding: 20px 24px 0;
        h3 {
          font-size: 18px;
          line-height: 29px;
          font-weight: bold;
        }
        p {
          margin-top: 8px;
          font-size: 12px;
          color: #41475e;
          line-height: 20px;
          text-align: justify;
          .show-lines(@lines:3, @maxHeight:60px);
        }
      }
    }
  }
  // ul {
  //     li {
  //         padding: 0 30px 48px;
  //         display: inline-block;
  //         width: 316px;
  //         height: 310px;
  //         img {
  //             width: 316px;
  //             height: 178px;
  //             display: block;
  //         }
  //     }
  // }
}
</style>
