<template>
  <div id="app">
    <div v-if="disabled">
      <Header></Header>
      <router-view :key="$route.fullPath" />
      <Footer></Footer>
    </div>
    <div v-if="!disabled">
      <router-view />
    </div>
  </div>
</template>

<script>
import { env } from "@/utils/getEnv.js";
import { Header, Footer } from "@/components/index";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      disabled: true,
      config: {},
    };
  },
  provide: function () {
    return {
      config: () => this.config,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path.indexOf("/mobile") !== -1) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
  created() {
    fetch(
      `https://static.nio.com/fx-static/4349/fixed/config.${
        env === "prod" ? "prod" : "test"
      }.json?t=${Date.now()}`
    )
      .then((r) => r.json())
      .then((res) => {
        this.config = res;
      });
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e)); //监听页面刷新触发事件
  },
  methods: {
    beforeunloadHandler(e) {
      console.log(e);
      console.log("浏览器刷新"); //防止页面刷新页面丢失判断的逻辑
    },
  },
  destroyed() {
    //进行监听销毁
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
};
</script>

<style lang="less">
#app {
  height: 100%;
}

// #app {
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
