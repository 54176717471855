import {Loading} from 'element-ui'
import {errorResult, successResult} from './entity/result'
import ahaszAxios from './axios-request'
import {errorClose} from './message'
import {getLocalCache, getSessionCache} from './cache'

/**
 * 等待请求框
 */
const initLoading = (config) => {
    const loading = Loading.service({
        target: config.headers.loadingDom,
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.8)'
    })
    return loading
}

let request = null

const installRequest = (hoseleConfig = {}) => {
    let axiosRequest = {
        requestInstance: null,
        loading: null,
        errorClose: false,
        create: function (hoseleConfig) {
            this.requestInstance = ahaszAxios.installRequest(hoseleConfig)
        },
        addHeader: function (config) {
            /*let oauthToken = getLocalCache('hosele_auth')
            if (oauthToken) {
                config.headers['Authorization'] = 'Bearer ' + oauthToken
            }*/
            let _view = getSessionCache("_view");
            config.headers['_view'] = _view
        },
        interceptors: function (axiosInstance) {
            axiosInstance.interceptors.request.use((config) => {
                this.addHeader(config)
                if (config.headers.loadingDom) {
                    this.loading = initLoading(config)
                }
                return config
            }, (error) => {
                return Promise.reject(error)
            })
            axiosInstance.interceptors.response.use((response) => {
                if (this.loading) {
                    this.closeLoading(this.loading)
                }
                if (response.isAxiosError) {
                    if (response.config.errorClose != undefined) {
                        if (response.config.errorClose == true) {
                            errorClose(response.message)
                        }
                    } else if (this.errorClose == true) {
                        errorClose(response.message)
                    }
                } else if (response.data instanceof Blob) {
                    return response
                } else if (response.data && response.data.code == undefined) {
                    return response
                } else if (response.data && response.data.code != 200) {
                    if (response.config.errorClose != undefined) {
                        if (response.config.errorClose == true) {
                            errorClose(response.data.msg)
                        }
                    } else if (this.errorClose == true) {
                        errorClose(response.data.msg)
                    }
                }
                return response
            }, (error) => {  //默认除了 2XX 之外的，其他全都进入这里处理
                if (this.loading) {
                    this.closeLoading(this.loading)
                }
                if (error.isAxiosError && this.errorClose == true) {
                    console.error(error)
                }
                return error
            })
        },
        postXForm: function (url, params = {}, hoseleConfig = {}) {
            return this.requestInstance.postXForm(url, params, hoseleConfig)
        },
        postObjFormData: function (url, params = {}, hoseleConfig = {}) {
            return this.requestInstance.postObjFormData(url, params, hoseleConfig)
        },
        postFormData: function (url, params = new FormData(), hoseleConfig = {}) {
            return this.requestInstance.postFormData(url, params, hoseleConfig)
        },
        postJson: function (url, params = {}, hoseleConfig = {}) {
            return this.requestInstance.postJson(url, params, hoseleConfig)
        },
        postFile: function (url, params = {}, hoseleConfig = {}) {
            return this.requestInstance.postFile(url, params, hoseleConfig)
        },
        postBigFile: function (url, params = {}, hoseleConfig = {}) {
            let promise = new Promise((resolve, reject) => {
                try {
                    return this.requestInstance.postBigFile(url, params, hoseleConfig).then(([results, finish]) => {
                        if (results.length <= 0) {
                            resolve(successResult('上传成功', finish.data))
                        } else {
                            resolve(errorResult('上传失败', results))
                        }
                    }).cache(e => {
                        let error = new Error(e)
                        reject(errorResult(e.message, e))
                    })
                } catch (e) {
                    let error = new Error(e)
                    reject(errorResult(e.message, e))
                }
            })
        },
        openErrorClose: function (url, params = {}, hoseleConfig = {}) {
            this.errorClose = true
        },
        closeErrorClose: function (url, params = {}, hoseleConfig = {}) {
            this.errorClose = false
        },
        closeLoading: (loading) => {
            loading.close()
            loading = null
        }
    }
    return axiosRequest
}

const installAxios = (hoseleConfig = {}) => {
    request = installRequest(hoseleConfig)
    request.create(hoseleConfig)
    request.interceptors(request.requestInstance.axiosInstance)
    return request
}

const createRequest = (hoseleConfig = {}) => {
    let axiosRequest = installRequest(hoseleConfig)
    axiosRequest.create(hoseleConfig)
    return axiosRequest
}

const postXForm = function (url, params = {}, hoseleConfig = {}) {
    return request.postXForm(url, params, hoseleConfig)
}
const postObjFormData = function (url, params = {}, hoseleConfig = {}) {
    return request.postObjFormData(url, params, hoseleConfig)
}
const postFormData = function (url, params = new FormData(), hoseleConfig = {}) {
    return request.postFormData(url, params, hoseleConfig)
}
const postJson = function (url, params = {}, hoseleConfig = {}) {
    return request.postJson(url, params, hoseleConfig)
}

const postFile = function (url, params = {}, hoseleConfig = {}) {
    return request.postFile(url, params, hoseleConfig)
}

const postBigFile = function (url, params = {}, hoseleConfig = {}) {
    return request.postBigFile(url, params, hoseleConfig)
}

const addGlobalHeaderParams = function (key, value) {
    request.addGlobalHeaderParams(key, value)
}

const openErrorClose = function (key, value) {
    request.addGlobalHeaderParams(key, value)
}

const closeErrorClose = function (key, value) {
    request.addGlobalHeaderParams(key, value)
}

export default {
    install(Vue) {
        installAxios({})
    },
    installAxios,
    createRequest,
    openErrorClose,
    closeErrorClose,
    addGlobalHeaderParams,
    postJson,
    postFile,
    postBigFile,
    postFormData,
    postObjFormData,
    postXForm
}
