<template>
    <ul class="process-step" :style="{'marginBottom': mBtm + 'px'}">
        <li class="process-steps" v-for="(item, index) in data" :key="index">
            <div class="process-steps-img">
            <!--<img :src="item.flowImage" alt="">-->
                <span class="pic-wrap">
                    <el-image :src="item.flowImage">
                        <div slot="placeholder" class="image-holder"></div>
                    </el-image>
                </span>
            </div>
            <div class="process-steps-name">{{item.flowName}}</div>
            <div class="process-steps-time" v-html="`${item.flowRemark.replace(/\\n/g, '<br>')}`"></div>
            <div class="process-steps-time" v-if="item.time">{{item.time}}</div>
            <div class="arrow-icon" v-show="index < data.length - 1">
                <i class="right" style="font-size:24px;color:#00BEBE;"></i>
            </div>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'RecruitmentProcess',
    props: {
        data: {
            type: Array,
            default: [],
            require: true
        },
        mBtm: {
            type: Number,
            default: 0,
            require: false
        }
    },

    created() { },
    data() {
        return {}
    }
}
</script>
<style lang="less" scoped>
.process-step {
    display: flex;
    justify-content: center;
    text-align: center;
    .process-steps {
        position: relative;
        // margin: 0 46px;
        margin: 0 7px;
        width: 180px;
        // height: 260px;
        text-align: center;
        &:last-child {
            margin-right: 0;
        }
        .arrow-icon {
            position: absolute;
            // top: 89px;
            // right: -58px;
            top: 62px;
            right: -20px;
            // width: 24px;
            // height: 24px;
            width: 19px;
            height: 22px;
            .right{
                display: inline-block;
                // width: 24px;
                // height: 24px;
                 width: 19px;
                 height: 22px;
                background-size: cover;
                // background-image: url(~@/assets/img/process-right.png);
                background-image: url(~@/assets/img/school/process-sanjiao.png);
            }
        }
        .process-steps-img {
            display: inline-block;
            margin: 20px auto;
            // width: 146px;
            // height: 146px;
            width: 106px;
            height: 106px;
            padding: 25px;
            border-radius:50%;
            background-color: #fff;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .process-steps-name {
            padding-bottom: 8px;
            font-size: 18px;
            font-weight: 500;
            color: rgba(4, 11, 41, 1);
        }
        .process-steps-time {
          width: 180px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: rgba(65, 71, 94, 1);
        }
    }
}
</style>