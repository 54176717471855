const isIE = function () {
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
        return true
    } else {
        return false
    }
}
const isSafari = function () {
    return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
}

exports.install = function (Vue, options) {

    Vue.prototype.$newTab = params => {
        let routeUrl = options.router.resolve(params)
        window.open(routeUrl.href, '_blank')
    }
    Vue.prototype.$push = params => {
        options.router.push(params)
    }

    Vue.prototype.$openLink = (url, target = '_self', download = false) => {
        let a = document.createElement('a')
        a.href = url
        a.target = target
        if (download) a.download = true
        document.body.appendChild(a)
        a.click()
        a.remove()
    }

    Vue.prototype.$preloadImg = (url) => {
        if (url && url.trim()) {
            new Image().src = url
        }
    }

    Vue.prototype.isIE = isIE
    Vue.prototype.isSafari = isSafari

    Vue.prototype.$smoothDown = (el, delay = 0) => {
        // console.log('isIE:', isIE())
        let node = (typeof el) === 'string' ? document.getElementById(el) : el,
            distance = node.offsetTop,
            parent = node.offsetParent
        if (!isIE() && !isSafari) {
            node.scrollIntoView({ behavior: 'smooth' })
        } else {
            while (parent && parent.offsetTop > 0) {
                distance += parent.offsetTop
                parent = parent.offsetParent
            }
            // console.log('node:', node)
            // console.log('distance:', distance)
            let scrolled = 0

            function smoothDown() {
                if (scrolled < distance && scrolled < distance - 1) {
                    // scrolled += step
                    scrolled += (distance - scrolled) / 10

                    // 移动一小段
                    // console.log('scrolled:', scrolled)
                    document.body.scrollTop = scrolled
                    document.documentElement.scrollTop = scrolled
                    requestAnimationFrame(smoothDown)
                } else {
                    // 限制滚动停止时的距离
                    document.body.scrollTop = distance
                    document.documentElement.scrollTop = distance
                }
            }

            requestAnimationFrame(smoothDown)
        }
    }

}