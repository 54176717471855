<template>
  <div class="main-w">
    <div class="main inputStyle">
      <input
        type="text"
        name="lname"
        class="input"
        v-model="value"
        placeholder="没有找到想要的职位？搜索关键字试试～"
        @keyup.enter="search(value)"
      />
      <i class="search" @click="search(value)"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",

  data() {
    return {
      value: "",
    };
  },

  mounted() {},

  methods: {
    search(v) {
      window.open("https://nio.jobs.feishu.cn/campus?keywords=" + v, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.main-w {
  width: 100%;
}
.inputStyle {
  margin: 24px auto 60px;
  text-align: center;
  position: relative;
  width: 488px;
  height: 48px;
}
.input {
  width: 488px;
  border: 1px solid #d9d9d9;
  //border-radius: 44px;
  outline: none;
  //padding: 11px 0px 10px 47px;
  height: 48px;
  padding-left: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  background: #ffffff;
}
.search {
  position: absolute;
  right: 16px;
  top: 14px;
  background: url("../../assets/img/arrow.png");
  background-size: cover;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
input::-webkit-input-placeholder {
  /* WebKit browsers*/
  color: rgba(4, 11, 41, 0.5);
  font-size: 16px;
}
input:-moz-input-placeholder {
  /* Mozilla Firefox 4 to 18*/
  color: rgba(4, 11, 41, 0.5);
  font-size: 16px;
}
input::-moz-input-placeholder {
  /* Mozilla Firefox 19+*/
  color: rgba(4, 11, 41, 0.5);
  font-size: 16px;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+*/
  color: rgba(4, 11, 41, 0.5);
  font-size: 16px;
}
</style>
